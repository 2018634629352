import { Button, Form, Input, Modal, Select, notification, Col, Space, Checkbox, MinusCircleOutlined, PlusOutlined, List } from "antd";
import React from "react";
import TrainingCenters from "./TrainingCenters";
import { EditOutlined } from '@ant-design/icons';

const { Option } = Select;

class EditTrainingCenterModal extends React.Component {
  formRef = React.createRef();

  state = {
    visible: false,
    training_center_levels: [],
    levels_not_training_center: []
  };

  componentDidMount() {
    this.getTrainingCenterLevels();
    this.getLevelsNotTrainingCenter();
  }

  getTrainingCenterLevels = () => {
    const url = `/api/v1/training_centers/${this.props.training_center.id}/get_training_center_levels`;
    fetch(url, {
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
    .then((data) => {
      if (data.ok) {
        return data.json();
      }
      throw new Error("Network error.");

    }).then((data) => {
      this.setState({
        training_center_levels: data.map(item => ({
          id: item.id,
          number_of_licenses: item.number_of_licenses,
          training_center_number: item.training_center_number,
          level_name: item.level_name,
          course_name: item.course_name
          // level_instructor: item.level_instructor,
          // email_instructor: item.instructors[0].email,
          // name_instructor: item.instructors[0].name,
          // surname_instructor: item.instructors[0].surname
        }))   
      }); 
    })
    .catch();
  };

  getLevelsNotTrainingCenter = () => {
    const url = `/api/v1/training_centers/${this.props.training_center.id}/get_levels_not_training_center`;
    fetch(url, {
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
    .then((data) => {
      if (data.ok) {
        return data.json();
      }
      throw new Error("Network error.");

    }).then((data) => {
      this.setState({
        levels_not_training_center: data.map(item => ({
          id: item.id,
          name: item.name,
          training_center_number: item.training_center_number,
          id_course_level_moodle: item.id_course_level_moodle,
          id_evaluation_level_moodle: item.id_evaluation_level_moodle,
          is_instructor: item.is_instructor,
          course_name: item.course_name
        }))   
      }); 
    })
    .catch();
  };

 
  onFinish = (values) => {
    // /api/v1/training_centers/:training_center_id/update_training_center
    const url = `api/v1/training_centers/${this.props.training_center.id}/update_training_center`;
    fetch(url, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"

      },
      body: JSON.stringify(values),
    })
      .then((data) => {
        if (data.ok) {
          this.handleCancel();
          this.formRef.current.resetFields(); 
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then(() => {
        this.props.reloadTrainingCenters();
        notification.open({
                  message: 'Centro modificado correctamente!',
                  placement: 'bottom',
                  icon: <EditOutlined style={{ color: '#394088' }} />,
                  duration: 4
                });
      })
      .catch((err) => console.error("Error: " + err));
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false
    });
  };


  render() {
    return (
      <>
        <Button className="action-btn" type="primary" onClick={()=>this.showModal()}>
          <i className="bi bi-pencil-square"></i>
        </Button>

        <Modal title="Editar Centro de Formación ..." visible={this.state.visible} onCancel={this.handleCancel} footer={null} width={1000} style={{ top: 20 }}>
          <Form ref={this.formRef} layout="vertical" onFinish={this.onFinish} 
              initialValues={{ name: this.props.training_center.name, training_center_number: this.props.training_center.training_center_number,
                                training_center_email: this.props.training_center.training_center_email}}>

            <Form.Item name="name" label="Nombre del centro" rules={[{ required: true, message: "Por favor, introduce el nombre del centro formativo" }]}>
              <Input placeholder="Nombre del centro formativo"  />
            </Form.Item>

            <Form.Item name="training_center_number" label="Código del centro">
              <Input placeholder="Código del centro formativo" />
            </Form.Item>

            <Col className="gutter-row" span={24}>
              <Form.List name="training_center_levels" initialValue={this.state.training_center_levels}>
                {(fields, { add, remove, ...restField }) => (
                  <>
                    {fields.map((field, index) => (
                      <Space key={field.key} style={{ display: "flex", marginBottom: 0 }} align="baseline">   
                        <p>{this.state.training_center_levels[index].course_name + " (" + this.state.training_center_levels[index].level_name + ")"}</p>
                        <Form.Item
                          {...restField}
                          name={[field.name, "number_of_licenses"]}
                          fieldKey={[field.fieldKey, "number_of_licenses"]}                         
                        >
                          <Input placeholder="Número de licencias para este nivel" />
                        </Form.Item>

                        {/*{(() => {
                          if (this.state.training_center_levels[index].level_instructor) {
                            return (
                              <>
                                <Form.Item
                                  {...restField}
                                  name={[field.name, "name_instructor"]}
                                  fieldKey={[field.fieldKey, "name_instructor"]}
                                  label={[field.label, "Nombre del instructor"]}
                                >
                                  <Input placeholder="Nombre del instructor a añadir" />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  name={[field.name, "surname_instructor"]}
                                  fieldKey={[field.fieldKey, "surname_instructor"]}
                                  label={[field.label, "Apellidos del instructor"]}
                                >
                                  <Input placeholder="Apellidos del instructor a añadir" />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  name={[field.name, "email_instructor"]}
                                  fieldKey={[field.fieldKey, "email_instructor"]}
                                  label={[field.label, "Email del instructor"]}
                                >
                                  <Input placeholder="Email del instructor a añadir" />
                                </Form.Item>
                              </>
                            )
                          }
                        })()}*/}   

                      </Space>  
                    ))}
                  </>
                )}
              </Form.List>
            </Col>

            <Col className="gutter-row" span={24}>
              <Form.List name="levels_not_training_center" initialValue={this.state.levels_not_training_center} >
                {(fields_level, { ...restFieldLevel }) => (
                  <>
                    {fields_level.map((field_level, index) => (
                      <Space key={field_level.key} style={{ display: "flex", marginBottom: 8 }} align="baseline"> 
                        <p>{this.state.levels_not_training_center[index].course_name + " (" + this.state.levels_not_training_center[index].name + ")"}</p>
                        <Form.Item
                          {...restFieldLevel}
                          name={[field_level.name, "number_of_licenses"]}
                          fieldKey={[field_level.fieldKey, "number_of_licenses"]}
                        >
                          <Input placeholder="Número de licencias para este nivel" />
                        </Form.Item>
                
                      </Space>  
                    ))}
                  </>
                )}
              </Form.List>
            </Col>


            <Form.Item>
              <Button type="primary" className="primary-btn" htmlType="submit">
                Actualizar
              </Button>
              <Button type="danger" onClick={()=>this.handleCancel()}>
                Cancelar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default EditTrainingCenterModal;