import { Row, Col} from "antd";
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import defaultImg from 'images/default_center_img';
import Users from "../users/Users";
import TrainingCentersUsers from "../training_centers/TrainingCentersUsers";

class TrainingCentersShow extends Component {
    state = {
        training_centers: [],
    };

    UNSAFE_componentWillMount() {
        this.loadTrainingCenter(window.location.pathname.split("/").pop());
    }

   loadTrainingCenter = (id) => {
     const url = `/api/v1/training_centers/${id}`;
     fetch(url, {
       method: "get",
       headers: {
         Accept: "application/json, text/plain, */*",
         Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
       },})
       .then((data) => {
         if (data.ok) {
           return data.json();
         }
         throw new Error("Network error.");
       })
       .then((data) => {

          const newEl = {
            key: data.id,
            id: data.id,
            name: data.name,
            number_of_licenses_availables: data.licenses_availables,
            number_of_licenses_used_student: data.licenses_used_student,
            number_of_licenses_used_instructor: data.licenses_used_instructor,
            number_of_total_licenses: data.total_licenses,
            training_center_email: data.users.email
          };

          this.setState({
              ...this.state, // Not required but just a heads up on using mutation
              training_centers: newEl
          })
       })
       .catch((err) => message.error("Error: " + err));
   };

    render() {
      return(
        <>
          <Row gutter={16} className="top-row">
            <Col className="gutter-row" xl={24}>
             <h1> <Link className="icon-primary" to={"/training_centers"}><i className="bi bi-arrow-left-square-fill"></i></Link> Centro: {this.state.training_centers.name}</h1>
            </Col>
            <Col className="gutter-row text-end" xl={12}>
             
            </Col>
            <Col className="gutter-row" xl={4}>
              
            </Col>

            <div className="show" style={{padding: "0px"}}>
              <Row>
                <Col span={8} style={{"maxHeight": "300px"}} >
                  <img className="logo w-100 h-100" src={defaultImg} style={{"objectFit": "cover"}} />
                </Col>
                <Col span={16} style={{padding: "25px 50px"}}>
                  <h2>{this.state.training_centers.name}</h2> 

                  <p className="label_name"><i className="bi bi-envelope-fill"></i> Email del administrador</p>
                  <p>{this.state.training_centers.training_center_email}</p>

                  <p className="label_name"><i className="bi bi-person-badge-fill"></i> Número total de licencias del centro</p>
                  <p>{this.state.training_centers.number_of_total_licenses}</p>

                  <p className="label_name"><i className="bi bi-person-badge-fill"></i> Número de licencias utilizadas</p>
                  <p>{this.state.training_centers.number_of_licenses_availables}</p>

                  <p className="label_name"><i className="bi bi-person-badge-fill"></i> Número de licencias utilizadas de alumno</p>
                  <p>{this.state.training_centers.number_of_licenses_used_student}</p>

                  <p className="label_name"><i className="bi bi-person-badge-fill"></i> Número de licencias utilizadas de instructor</p>
                  <p>{this.state.training_centers.number_of_licenses_used_instructor}</p>
                </Col>
              </Row>

              <div className="show-separator-title-box">Usuarios</div>
                
              <Row className="training-center-show">
                <Col span={24} style={{padding: "25px 50px"}}>
                  <TrainingCentersUsers training_center = {this.state.training_centers} />
                </Col>
              </Row>
            </div>
          </Row>
        </>
      );
    }
}

export default TrainingCentersShow;