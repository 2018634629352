import { Row, Col, Table, message, notification ,Popconfirm, Button, Tooltip } from "antd";
import React from "react";
import { Link } from 'react-router-dom';
import AddCourseModal from "../courses/AddCourseModal";
import EditCourseModal from "../courses/EditCourseModal";
import SearchBar from "components/SearchBar";
import { DeleteOutlined } from '@ant-design/icons';

class UsersCourses extends React.Component {
  columns = [];

  constructor(props) {
    super(props);
  }

  state = {
    courses: [],
    userRole: [],
    userLogin: [],
  };

  componentDidMount() {
    this.getUserCondition();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.loadCourses(this.props.user.id);
    }
  }

  loadCourses = (id) => {
    const url = `/api/v1/users/${id}/get_user_courses`;
    fetch(url, {
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },
    }).then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then((data) => {
        data.forEach((course) => {
          const newEl = {
            key: course.id,
            id: course.id,
            name: course.name,
            id_course_moodle: course.id_course_moodle,
            moodle_link: course.moodle_link

          };

          this.setState((prevState) => ({
            courses: [...prevState.courses, newEl],
          }));
        });
      })
      .catch((err) => message.error("Error: " + err));
  };

  reloadCourses = () => {
    this.setState({ courses: [] });
    this.loadCourses(this.props.user.id);
  };

  deleteCourse = (id) => {
    const url = `api/v1/courses/${id}/destroy_course`;
    fetch(url, {
      method: "delete",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
      .then((data) => {
        if (data.ok) {
          this.reloadCourses();
          return data.json();
        }
        throw new Error("Network error.");
      }).then(() => {
        this.cursosSearhBar.value = "";
        notification.open({
                  message: 'Curso eliminado correctamente!',
                  placement: 'bottom',
                  icon: <DeleteOutlined style={{ color: '#ff3232'}} />,
                  duration: 4
                });
      })
      .catch((err) => message.error("Error: " + err));
  };


  search = (evt) => {
    var currentValue = evt.target.value;
    console.log("SearchBar => "+currentValue);
    if (currentValue != "") {
      this.setState({ courses: this.state.courses.filter(
        courses => courses.name.toUpperCase().includes(currentValue.toUpperCase())) 
      });
    } else {
      this.setState({ courses: [] });
      this.loadCourses(this.props.user.id);
    }
    
  };

  getUserCondition = () => {
    const url = "/api/v1/users/get_user_condition";
    fetch(url, {
      headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
      .then((data) => {
      if (data.ok) {
        return data.json();
      }
      throw new Error("Network error.");
      }).then((data) => {
      this.setState({ userRole: data});
      })
      .catch();
  };

  render() {

    //Add Course button condition
    let buttons;
    if (this.state.userRole.notice != undefined) {
      switch (this.state.userRole.notice) {
        case "training_center_admin":
          buttons = ""
          break;
        case "admin":
          buttons = <AddCourseModal reloadCourses={this.reloadCourses} />
          break;
        case "student":
          buttons = ""
          break;
        case "instructor":
          buttons = ""
          break;
        default:
          buttons = ""
          break;        
      }
      
    }

    //Edit and Destroy buttons condition
    let columns=[];
    if (this.state.userRole.notice != undefined) {
      if (this.state.userRole.notice == "admin") { 
        columns = [
          {
            title: "Nombre" ,
            dataIndex: "name",
            className: "table-name",
            render: (text, record) => (
              <>
              {record.name}
              </>
            ),
            sorter: (a, b) => { return a.name.localeCompare(b.name)},
          },
        ];
      }
      else if (this.state.userRole.notice == "training_center_admin" || this.state.userRole.notice == "student"){
        columns = [
          {
            title: "Nombre" ,
            dataIndex: "name",
            render: (text, record) => (
              <>
                {record.name}
                <i className="mobile bi bi-chevron-right"></i>
              </>
            ),
            sorter: (a, b) => { return a.name.localeCompare(b.name)},
          },                         
        ];
      }
    }
      
    return (
      <>
        <Row gutter={16} className="top-row">
            <Col className="gutter-row" xl={8}>
             <h1>Cursos</h1>
            </Col>
            <Col className="gutter-row text-end search-col" xl={16}>
             <div className="search-bar">
                <button className="search-btn">
                  <i className="bi bi-search"></i>
                </button>
                <input ref={ref => this.cursosSearhBar = ref} id="cursos-search-bar" onChange={this.search} type="text" className="search-input" placeholder="Buscar..." />
              </div>
            </Col>
          </Row>
          <Table
          className="table-striped-rows"
          dataSource={this.state.courses}
          columns={columns}
          pagination={{ pageSize: 4 }}
          locale={{ 
            triggerDesc: 'Orden ascendente',
            triggerAsc: 'Orden descendente', 
            cancelSort: 'Mostrar sin ordenar'
          }}
        />
      </>
    );
  }
}

export default UsersCourses;
