import React from "react";
import { Row, Col, Table, message, notification ,Popconfirm, Button, Tooltip } from "antd";
import AddCourseSpecialistModal from "./AddCourseSpecialistModal";
import { DeleteOutlined } from '@ant-design/icons';
import EditCourseSpecialistModal from "./EditCourseSpecialistModal";

class CoursesSpecialists extends React.Component {

    state = {
        specialists: [],
    };

    componentDidMount() {
        this.loadSpecialists();
        this.getUserCondition();
    }

    loadSpecialists = () => {
        const url = "api/v1/specialists";
        fetch(url, {
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
          },
        }).then((data) => {
            if (data.ok) {
              return data.json();
            }
            throw new Error("Network error.");
          })
          .then((data) => {
            data.forEach((specialist) => {
              const newEl = {
                key: specialist.id,
                id: specialist.id,
                name: specialist.name,
                position: specialist.position,  
              };
    
              this.setState((prevState) => ({
                specialists: [...prevState.specialists, newEl],
              }));
            });
          })
          .catch((err) => message.error("Error: " + err));
    };

    getUserCondition = () => {
		const url = "api/v1/users/get_user_condition";
		fetch(url, {
		  headers: {
			Accept: "application/json, text/plain, */*",
			Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
		  },})
		  .then((data) => {
			if (data.ok) {
			  return data.json();
			}
			throw new Error("Network error.");
		  }).then((data) => {
			this.setState({ userRole: data});
		  })
		  .catch();
	};

    reloadSpecialists = () => {
        this.setState({ specialists: [] });
        this.loadSpecialists();
    };

    deleteSpecialist = (id) => {
        const url = `api/v1/specialists/${id}/destroy_specialist`;
        fetch(url, {
          method: "delete",
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
          },})
          .then((data) => {
            if (data.ok) {
              this.reloadSpecialists();
              return data.json();
            }
            throw new Error("Network error.");
          }).then(() => {
            this.cursosSearhBar.value = "";
            notification.open({
                      message: 'Esp. eliminado correctamente!',
                      placement: 'bottom',
                      icon: <DeleteOutlined style={{ color: '#ff3232'}} />,
                      duration: 4
                    });
          })
          .catch((err) => message.error("Error: " + err));
    };

    columns = [
        {
            title: "Nombre" ,
            dataIndex: "name",
            className: "table-name",
            key: "name",
            sorter: (a, b) => { return a.name.localeCompare(b.name)},
        },
        {
            title: "Acciones",
            key: "action",
            render: (_text, record) => (
              <>
              <EditCourseSpecialistModal specialist = {record} reloadSpecialists={this.reloadSpecialists} />
              <Popconfirm
                title="Seguro que quiere borrar el especialista?"
                onConfirm={() => this.deleteSpecialist(record.id)}
                okText="Si"
                cancelText="No"
              >
                <Button className="action-btn" href="#" type="danger">
                  <i className="bi bi-trash3"></i>
                </Button>
              </Popconfirm>
              </>
            ),
          },
    ];

    search = (evt) => {
        var currentValue = evt.target.value;
        console.log("SearchBar => "+currentValue);
        if (currentValue != "") {
            this.setState({ specialists: this.state.specialists.filter(
            specialists => specialists.name.toUpperCase().includes(currentValue.toUpperCase())) 
            });
        } else {
            this.setState({ specialists: [] });
            this.loadSpecialists();
        }    
    };


    render() {
        return (
            <>
                <br /><br /><br />
                <Row gutter={16} className="top-row">
                    <Col className="gutter-row" xl={8}>
                        <h1>Especialistas</h1>
                    </Col>
                    <Col className="gutter-row text-end search-col" xl={12}>
                        <div className="search-bar">
                            <button className="search-btn">
                            <i className="bi bi-search"></i>
                            </button>
                            <input ref={ref => this.cursosSearhBar = ref} id="cursos-search-bar" onChange={this.search} type="text" className="search-input" placeholder="Buscar..." />
                        </div>
                    </Col>
                    <Col className="gutter-row" xl={4}>
                        <AddCourseSpecialistModal reloadSpecialists={this.reloadSpecialists} />
                    </Col>
                </Row>
                <Table
                className="table-striped-rows"
                dataSource={this.state.specialists}
                columns={this.columns}
                pagination={{ pageSize: 7 }}
                locale={{ 
                    triggerDesc: 'Orden ascendente',
                    triggerAsc: 'Orden descendente', 
                    cancelSort: 'Mostrar sin ordenar'
                }}
                />
                
            </>
        )
    }
}

export default CoursesSpecialists;