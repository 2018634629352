import { Row, Col, Table, message, Popconfirm, Button, notification, Input, Search} from "antd";
import React from "react";
import { Link } from "react-router-dom";
import AddUserModal from "./AddUserModal";
import EditUserModal from "./EditUserModal";
import SearchBar from "components/SearchBar";
import { DeleteOutlined } from '@ant-design/icons';

class Users extends React.Component {
  
  columns = [
    {
      title: "Nombre" ,
      dataIndex: "name",
      className: "table-name",
      render: (text, record) => (
        <> 
          <Link to={"/user/"+record.id}>{record.name}</Link>
          <i className="mobile bi bi-chevron-right"></i>
          <EditUserModal user = {record} reloadUsers={this.reloadUsers} reloadLicenses={this.reloadLicenses} /> 
          <Popconfirm
            title="Seguro que quiere borrar el Usuario?"
            onConfirm={() => this.deleteUser(record.id)}
            okText="Si"
            cancelText="No"
          >
            <Button className="mobile action-btn" href="#" type="danger">
              <i className="bi bi-trash3"></i>
            </Button>
          </Popconfirm> 
        </>
      ),
      sorter: (a, b) => { return a.name.localeCompare(b.name)},
    },
    {
      title: "Apellidos" ,
      dataIndex: "surname",
      sorter: (a, b) => { return a.surname.localeCompare(b.surname)},
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => (
        <Link to={"/user/"+record.id}>{record.email}</Link>
      ),
      sorter: (a, b) => { return a.email.localeCompare(b.email)},
    },
    {
      title: "Rol",
      dataIndex: "user_type",
      key: "user_type",
      render: (_text, record) => (
        <>
          {record.user_type == "student"
            ? <>
                Alumno
              </>
            : <>
                Instructor
              </>     
          }  
        </>       
      ),
      sorter: (a, b) => { return a.user_type.localeCompare(b.user_type)},
    },
    {
      title: "Acciones",
      key: "action",
      render: (_text, record) => (
        <>
          {this.state.userRole.notice == "admin"
            ? <>
                <EditUserModal user = {record} reloadUsers={this.reloadUsers} reloadLicenses={this.reloadLicenses} />                 
                <Popconfirm
                  title="Seguro que quiere borrar el Usuario?"
                  onConfirm={() => this.deleteUser(record.id)}
                  okText="Si"
                  cancelText="No"
                >
                  <Button className="action-btn" href="#" type="danger">
                    <i className="bi bi-trash3"></i>
                  </Button>
                </Popconfirm> 
              </>
            : <>
                <EditUserModal user = {record} reloadUsers={this.reloadUsers} reloadLicenses={this.reloadLicenses} /> 
              </>     
          }  
        </>       
      ),
    }
  ];

  state = {
    users: [],
    userRole: [],
    licenses_available: [],
  };

  componentDidMount() {
    this.loadUsers();
    this.getUserCondition();
    this.getLicensesAvailables();
  }

  getLicensesAvailables = () => {
    const url = "/api/v1/users/get_licenses_availables";
    fetch(url, {
      headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
      .then((data) => {
      if (data.ok) {
        return data.json();
      }
      throw new Error("Network error.");
      }).then((data) => {
      this.setState({ licenses_available: data});
      })
      .catch();
  };

  getUserCondition = () => {
    const url = "/api/v1/users/get_user_condition";
    fetch(url, {
      headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
      .then((data) => {
      if (data.ok) {
        return data.json();
      }
      throw new Error("Network error.");
      }).then((data) => {
      this.setState({ userRole: data});
      })
      .catch();
  };

  loadUsers = () => {
    const url = "/api/v1/users";
    fetch(url, {
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },
    }).then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then((data) => {
        data.forEach((user) => {
          const newEl = {
            key: user.id,
            id: user.id,
            name: user.name,
            surname: user.surname,
            email: user.email,
            user_type: user.user_infos.user_type
          };

          this.setState((prevState) => ({
            users: [...prevState.users, newEl],
          }));
        });
      })
      .catch((err) => message.error("Error: " + err));
  };

  reloadUsers = () => {
    this.setState({ users: [] });
    this.loadUsers();
  };

  reloadLicenses = () => {
    this.setState({ licenses_available: [] });
    this.getLicensesAvailables();
  };

  deleteUser = (id) => {
    const url = `/api/v1/users/${id}/destroy_consumer`;
    fetch(url, {
      method: "delete",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
      .then((data) => {
        if (data.ok) {
          this.reloadUsers();
          return data.json();
        }
        throw new Error("Network error.");
      }).then(() => {
        this.usersSearchBar.value = "";
        notification.open({
                  message: 'Usuario eliminado correctamente!',
                  placement: 'bottom',
                  icon: <DeleteOutlined style={{ color: '#ff3232'}} />,
                  duration: 4
                });
      })
      .catch((err) => message.error("Error: " + err));
  };

  search = (evt) => {
    var currentValue = evt.target.value;
    console.log("SearchBar => " + currentValue);
    if (currentValue != "") {
      this.setState({ users: this.state.users.filter(
        user => user.email.toUpperCase().includes(currentValue.toUpperCase())) 
      });
    } else {
      this.setState({ users: [] });
      this.loadUsers();
    }
  };

  render() {
    let header;
    if (this.state.userRole.notice != undefined) {
      switch (this.state.userRole.notice) {
				case "admin":
					header =
          <>
            <Row gutter={16} className="top-row">
              <Col className="gutter-row" xl={8}>
              <h1>Usuarios</h1>
              </Col>
              <Col className="gutter-row text-end search-col" xl={12}>
              <div className="search-bar">
                <button className="search-btn">
                  <i className="bi bi-search"></i>
                </button>
                <input ref={ref => this.usersSearchBar = ref} id="cursos-search-bar" onChange={this.search} type="text" className="search-input" placeholder="Buscar..." />
              </div>
              </Col>
              <Col className="gutter-row" xl={0}>
                {/*<AddUserModal reloadUsers={this.reloadUsers} />*/}
              </Col>
            </Row>
          </>
				break;
				default:
					header =
          <>
            <Row gutter={16} className="top-row">
              <Col className="gutter-row" xl={8}>
              <h1>Usuarios</h1>
              </Col>
              <Col className="gutter-row text-end search-col" xl={16}>
              <div className="search-bar">
                <button className="search-btn">
                  <i className="bi bi-search"></i>
                </button>
                <input ref={ref => this.usersSearchBar = ref} id="cursos-search-bar" onChange={this.search} type="text" className="search-input" placeholder="Buscar..." />
              </div>
              </Col>
              <Col className="gutter-row" xl={0}>
                
              </Col>
            </Row>
          </>
				break;				
			}      
    }

    return (
      <>
        {header}        
        <Table
          className="table-striped-rows"
          dataSource={this.state.users}
          columns={this.columns}
          pagination={{ pageSize: 8 }}
          locale={{ 
            triggerDesc: 'Orden ascendente',
            triggerAsc: 'Orden descendente', 
            cancelSort: 'Mostrar sin ordenar'
          }}
        />
      </>
    );
  }
}

export default Users;
