import { Button, Form, Input, Modal, Select, notification, Checkbox} from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import React from "react";
import Courses from "components/courses/Courses";

const { Option, OptGroup } = Select;

class AddUserModal extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props)
  }

  state = {
    visible: false,
    total_levels: [],
    levels: [],
    licenses_available: [],
    levels_student: [],
    levels_instructor: [],
    value: "",
    level: ""
  };

  handleChange = (event) => {
    if (event == "student") {
      this.setState({total_levels: this.state.levels_student});
    } else if (event == "instructor") {
      this.setState({total_levels: this.state.levels_instructor});
    } else {
      this.setState({total_levels: this.state.levels_student});
    }
  };

  onFinish = (values) => {
    values = { ...values, level_id: this.props.level};
    const url = "/api/v1/users/create_consumer";
    fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },
      body: JSON.stringify(values),
    })
      .then((data) => {
        if (data.ok) {
          this.handleCancel();
          this.formRef.current.resetFields(); 
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then((data) => {
        if (data.notice == "Ha excedido el número máximo de licencias") {
          this.props.reloadUsers();
          notification.open({
            message: 'Has llegado al límite de licencias!',
            placement: 'bottom',
            icon: <CloseCircleOutlined style={{ color: '#ff3232'}} />,
            duration: 4
          });
        } else {
          this.props.reloadUsers();
          notification.open({
            message: 'Usuario añadido correctamente!',
            placement: 'bottom',
            icon: <CheckCircleOutlined style={{ color: '#4BDAB1' }} />,
            duration: 4
          });
        }
      })
      .catch((err) => console.error("Error: " + err));
  };

  componentDidMount() {
    this.loadLevels();
    this.loadLevelsStudent();
    this.loadLevelsInstructor();
    this.getLicensesAvailables();
    this.setState({level: this.props.level});
  }

  getLicensesAvailables = () => {
    const url = "/api/v1/users/get_licenses_availables";
    fetch(url, {
      headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
      .then((data) => {
      if (data.ok) {
        return data.json();
      }
      throw new Error("Network error.");
      }).then((data) => {
      this.setState({ licenses_available: data});
      })
      .catch();
  };

  loadLevels = () => {
    const url = "/api/v1/levels";
    fetch(url, {
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },
    }).then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then((data) => {
        data.forEach((level) => {
          const newEl = {
            key: level.id,
            id: level.id,
            name: level.name,
            id_course_level_moodle: level.id_course_level_moodle,
            id_evaluation_level_moodle: level.id_evaluation_level_moodle,
            is_instructor: level.is_instructor
          };

          this.setState((prevState) => ({
            levels: [...prevState.levels, newEl],
          }));
        });
      })
      .catch((err) => message.error("Error: " + err));
  };

  loadLevelsStudent = () => {
    const url = "/api/v1/levels/get_levels_student";
    fetch(url, {
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },
    }).then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then((data) => {
        data.forEach((level_student) => {
          const newElStudent = {
            key: level_student.id,
            id: level_student.id,
            name: level_student.name,
            id_course_level_moodle: level_student.id_course_level_moodle,
            id_evaluation_level_moodle: level_student.id_evaluation_level_moodle,
            is_instructor: level_student.is_instructor
          };

          this.setState((prevState) => ({
            levels_student: [...prevState.levels_student, newElStudent],
          }));
        });
      })
      .catch((err) => message.error("Error: " + err));
  };

  loadLevelsInstructor = () => {
    const url = "/api/v1/levels/get_levels_instructor";
    fetch(url, {
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },
    }).then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then((data) => {
        data.forEach((levels_instructor) => {
          const newElInstructor = {
            key: levels_instructor.id,
            id: levels_instructor.id,
            name: levels_instructor.name,
            id_course_level_moodle: levels_instructor.id_course_level_moodle,
            id_evaluation_level_moodle: levels_instructor.id_evaluation_level_moodle,
            is_instructor: levels_instructor.is_instructor
          };

          this.setState((prevState) => ({
            levels_instructor: [...prevState.levels_instructor, newElInstructor],
          }));
        });
      })
      .catch((err) => message.error("Error: " + err));
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  selectItems = (levels) => {
    return levels.map(function (level) {
      return (
         <Option value={level.id}>{level.name}</Option>
      )
    })
  }

  render() {
    const onCheckboxChange = selection => {
      console.log(selection);
    };

    return (
      <>
        <button className="primary-btn" type="primary" onClick={this.showModal}>
          <i className="bi bi-plus-lg"></i> Nuevo Usuario
        </button>

        <Modal title="Añade un usuario ..." visible={this.state.visible} onCancel={this.handleCancel} footer={null}>
          <Form ref={this.formRef} layout="vertical" onFinish={this.onFinish}>
            <Form.Item name="name" label="Nombre del usuario" rules={[{ required: true, message: "Por favor, introduce el nombre del usuario" }]}>
              <Input placeholder="Nombre del usuario" />
            </Form.Item>

            <Form.Item name="surname" label="Apellidos" rules={[{ required: true, message: "Por favor, introduce los apellidos del usuario" }]}>
              <Input placeholder="Apellidos del usuario" />
            </Form.Item>

            <Form.Item name="email" label="Email del usuario" rules={[{ required: true, message: "Por favor, introduce el email del usuario" }]}>
              <Input placeholder="Email del usuario" />
            </Form.Item>

            {/*<Form.Item name="user_type" label="Rol del usuario" rules={[{ required: true, message: "Por favor, selecciona el rol del usuario" }]}>
              <Select
                id="user_role" 
                placeholder="Selecciona el rol del usuario"
                defaultValue="student"
                onChange={this.handleChange}
                options={[
                  {
                    value: 'student',
                    label: 'Alumno',
                  },
                  {
                    value: 'instructor',
                    label: 'Instructor',
                  },
                ]}
              />                            
            </Form.Item>

            <Form.Item name="level_ids" label="Cursos y niveles relacionados" rules={[{ required: true, message: "Por favor, selecciona el nivel del curso" }]}>
              <Select 
                placeholder="Selecciona uno o varios niveles"
                mode="multiple"
              >
                {this.selectItems(this.state.total_levels)}
              </Select>  
            </Form.Item>*/}

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Insertar
              </Button>
              <Button type="danger" onClick={()=>this.handleCancel()}>
                Cancelar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default AddUserModal;