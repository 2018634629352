//React
import React from "react";
import Routes from "../routes/index";

//Styles
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import "../src/App.scss";

//Routes
export default () => <>{Routes}</>;
