import React from "react";

function SearchBar(props) {
	return(
		<>
		<div className="search-bar">
			<button className="search-btn">
				<i className="bi bi-search"></i>
			</button>
			<input id={props.id} type="text" className="search-input" placeholder="Buscar..." />
		</div>
		</>
	);
}

export default SearchBar;