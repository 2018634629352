import { Row, Col, Table, message, Popconfirm, Button, Switch, notification, Input, Search} from "antd";
import React from "react";
import { Link } from "react-router-dom";
import AddUserModal from "../users/AddUserModal";
import EditUserModal from "../users/EditUserModal";
import { DeleteOutlined } from '@ant-design/icons';
import EditLicenseModal from "../courses/EditLicenseModal";
import EditLevelModal from "../levels/EditLevelModal";

class LevelsUsers extends React.Component {

  constructor(props) {
    super(props);
  }

  state = {
    users: [],
    userRole: [],
    licenses: [],
    loading: false
  };

  columns = [
    {
      title: "Nombre" ,
      dataIndex: "name",
      className: "table-name",
      render: (text, record) => (
        <> 
          <Link to={"/user/"+record.id}>{record.name}</Link>
          <i className="mobile bi bi-chevron-right"></i>
          <EditUserModal user = {record} reloadUsers={this.reloadUsers} /> 
          <Popconfirm
            title="Seguro que quiere borrar el Usuario?"
            onConfirm={() => this.deleteUser(record.id)}
            okText="Si"
            cancelText="No"
          >
            <Button className="mobile action-btn" href="#" type="danger">
              <i className="bi bi-trash3"></i>
            </Button>
          </Popconfirm> 
        </>
      ),
      sorter: (a, b) => { return a.name.localeCompare(b.name)},
    },
     {
      title: "Apellidos" ,
      dataIndex: "surname",
      sorter: (a, b) => { return a.surname.localeCompare(b.surname)},
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => (
        <Link to={"/user/"+record.id}>{record.email}</Link>
      ),
      sorter: (a, b) => { return a.email.localeCompare(b.email)},
    },
    {
      title: "Rol",
      dataIndex: "user_type",
      key: "user_type",
      render: (_text, record) => (
        <>
          {record.user_type == "student"
            ? <>
                Alumno
              </>
            : <>
                Instructor
              </>     
          }  
        </>       
      ),
      sorter: (a, b) => { return a.user_type.localeCompare(b.user_type)},
    },
    {
      title: "Estado",
      dataIndex: "evaluable",
      render: (text, record) => (
        <>
          {record.evaluable
            ? <>
                <span className="success-chip"><i className="bi bi-check-circle-fill"></i> Evaluable</span>
              </>
            : <>
                <span className="fail-chip"><i className="bi bi-x-circle-fill"></i> Evaluable</span>
              </>
          }
          {record.passed
            ? <>
                <span className="success-chip"><i className="bi bi-check-circle-fill"></i> Aprobado</span>
              </>
            : <>
                <span className="fail-chip"><i className="bi bi-x-circle-fill"></i> Aprobado</span>
              </>
          }
          {this.state.userRole.notice == "admin" || !this.props.level.is_instructor  
            ? <>
                <EditLevelModal level = {record} reloadLevels={this.reloadLevels}/>
              </>
            : <>

              </>     
          }  
        </>
      ),
    },
    {
      title: "Acciones",
      key: "action",
      render: (_text, record) => (
        <>
          {this.state.userRole.notice == "admin"
            ? <>
                <EditUserModal user = {record} reloadUsers={this.reloadUsers} /> 
                <Popconfirm
                  title="Seguro que quiere borrar el Usuario?"
                  onConfirm={() => this.deleteUser(record.id)}
                  okText="Si"
                  cancelText="No"
                >
                  <Button className="action-btn" href="#" type="danger">
                    <i className="bi bi-trash3"></i>
                  </Button>
                </Popconfirm> 
              </>
            : <>
                <EditUserModal user = {record} reloadUsers={this.reloadUsers} /> 
              </>
          }                    
        </>
      ),
    }
  ];

  onChangeSwitchEvaluable = (license_id) => {
    console.log(this.state.loading);
    if(this.state.loading) {
      console.log("swith evaluable");
      const url = `/api/v1/licenses/${license_id}/switch_evaluable_state`;
      fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"

        },
      })
        .then((data) => {
          if (data.ok) {
            this.handleCancel();
            this.formRef.current.resetFields(); 
            return data.json();
          }
          throw new Error("Network error.");
        })
        .catch((err) => console.error("Error 1: " + err));
    } else {

    }
  };

  onChangeSwitchPassed = (license_id) => {
    console.log(this.state.loading);
    if(this.state.loading) {
      console.log("swith evaluable");
      const url = `/api/v1/licenses/${license_id}/switch_passed_state`;
      fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"

        },
      })
        .then((data) => {
          if (data.ok) {
            this.handleCancel();

            return data.json();
          }
          throw new Error("Network error.");
        })
        .catch((err) => console.error("Error 2: " + err));
    } else {

    }
  };

  componentDidMount() {
    this.loadUsers(window.location.pathname.split("/").pop());
    this.getUserCondition();
    //this.getUserLogin();
  }

  reloadLicenses = () => {
    this.setState((prevState) => ({
      users: [],
    }));
    this.loadUsers(window.location.pathname.split("/").pop());
  };

  reloadLevels = () => {
    this.setState((prevState) => ({
      users: [],
    }));
    this.loadUsers(window.location.pathname.split("/").pop());
  };

  componentDidUpdate(prevProps) {
    this.setApprovals(this.state.users);
  }

  getUserCondition = () => {
    const url = "/api/v1/users/get_user_condition";
    fetch(url, {
      headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
      .then((data) => {
      if (data.ok) {
        return data.json();
      }
      throw new Error("Network error.");
      }).then((data) => {
      this.setState({ userRole: data});
      })
      .catch();
  };

  setApprovals = (users) => {
    for (let index in users) {
      this.checkApproval(users[index].level_id, users[index].id);
    }
  };

  checkApproval = (level_id,user_id) => {
    const url = `/api/v1/levels/check_approvals/${level_id}/${user_id}`;
    fetch(url, {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },
    })
  };

  loadUsers = (id) => {
    console.log(id)
    const url = `/api/v1/levels/${id}/level_users`;
    fetch(url, {
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },
    }).then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then((data) => {
        data.forEach((user) => {
          const newEl = {
            key: user.id,
            id: user.id,
            name: user.name,
            surname: user.surname,
            email: user.email,
            level_id: user.levels[0].id,
            user_level_id: user.user_levels.id,
            evaluable: user.user_levels.evaluable,
            passed: user.user_levels.passed,
            user_type: user.user_infos.user_type
          };

          this.setState((prevState) => ({
            users: [...prevState.users, newEl],
            loading: false,
          }));
        });
      })
      .catch((err) => message.error("Error 3: " + err));
  };


  // loadUsers = (id) => {
  //   console.log(id)
  //   const url = `/api/v1/levels/${id}/level_users`;
  //   fetch(url, {
  //     headers: {
  //       Accept: "application/json, text/plain, */*",
  //       Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
  //     },
  //   }).then((data) => {
  //       if (data.ok) {
  //         return data.json();
  //       }
  //       throw new Error("Network error.");
  //     })
  //     .then((data) => {
  //       data.forEach((user) => {
  //         const newEl = {
  //           key: user.id,
  //           id: user.id,
  //           name: user.name,
  //           surname: user.surname,
  //           email: user.email,
  //           level_id: user.levels[0].id,
  //           evaluable: user.levels[0].evaluable,
  //           passed: user.levels[0].passed,
  //           user_type: user.user_infos.user_type
  //         };

  //         this.setState((prevState) => ({
  //           users: [...prevState.users, newEl],
  //           loading: false,
  //         }));
  //       });
  //     })
  //     .catch((err) => message.error("Error 3: " + err));
  // };


  reloadUsers = () => {
    this.setState({ users: [] });
    this.loadUsers(this.props.level.id);
  };

  deleteUser = (id) => {
    const url = `/api/v1/users/${id}/destroy_consumer`;
    fetch(url, {
      method: "delete",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
      .then((data) => {
        if (data.ok) {
          this.reloadUsers();
          return data.json();
        }
        throw new Error("Network error.");
      }).then(() => {
        this.usersSearchBar.value = "";
        notification.open({
                  message: 'Usuario eliminado correctamente!',
                  placement: 'bottom',
                  icon: <DeleteOutlined style={{ color: '#ff3232'}} />,
                  duration: 4
                });
      })
      .catch((err) => message.error("Error 4: " + err));
  };

  search = (evt) => {
    var currentValue = evt.target.value;
    console.log("SearchBar => " + currentValue);
    if (currentValue != "") {
      this.setState({ users: this.state.users.filter(
        user => user.email.toUpperCase().includes(currentValue.toUpperCase())) 
      });
    } else {
      this.setState({ users: [] });
      this.loadUsers(this.props.level.id);
    }
  };

  render() {
    // let course_show_details;
    // if (this.props.level != undefined) {
    //   switch (this.props.level.is_instructor) {
    //     case true:
    //       course_show_details = ""
    //     break;
    //     case false:
    //       course_show_details =
    //       <>
    //         <Col className="gutter-row" xl={4}>
    //           <AddUserModal level={this.props.level.id} reloadUsers={this.reloadUsers} />
    //         </Col>
    //       </>
    //     break;
    //   }
    // };

    return (
      <>
        <Row gutter={16} className="top-row">
          <Col className="gutter-row" xl={8}>
           <h1 style={{"display":"block"}}>Usuarios</h1>
          </Col>
          <Col className="gutter-row text-end search-col" xl={12}>
            <div className="search-bar">
              <button className="search-btn">
                <i className="bi bi-search"></i>
              </button>
              <input ref={ref => this.usersSearchBar = ref} id="cursos-search-bar" onChange={this.search} type="text" className="search-input" placeholder="Buscar por email..." />
            </div>
          </Col>
          {
            <>
              {this.state.userRole.notice == "training_center_admin"
                ? <>
                    <Col className="gutter-row" xl={4}>
                      <AddUserModal level={this.props.level.id} reloadUsers={this.reloadUsers} />
                    </Col>
                  </>
                : <>

                  </>     
              }  
            </>            
          }
        </Row>
        <Table
          className="table-striped-rows"
          dataSource={this.state.users}
          columns={this.columns}
          pagination={{ pageSize: 4 }}
          locale={{ 
            triggerDesc: 'Orden ascendente',
            triggerAsc: 'Orden descendente', 
            cancelSort: 'Mostrar sin ordenar'
          }}
        />
      </>
    );
  }
}

export default LevelsUsers;
