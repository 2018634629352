import { Button, Form, Input, Modal, Select, Row, Col, notification, Switch} from "antd";
import { CheckCircleOutlined } from '@ant-design/icons';
import TrainingCenters from "components/training_centers/TrainingCenters";
import React, { Component } from 'react';
import { EditorState, convertToRaw, convertFromRaw, convertFromHTML , ContentState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;


class EditLevelModal extends React.Component {

    constructor(props) {
        super(props);
        console.log(props)    
        this.state = {
            visible: false,
            evaluable: this.props.level.evaluable,
            passed: this.props.level.passed
        };
    
    };

    showModal = () => {
        this.setState({
          visible: true,
        });
    };

    handleCancel = () => {
        this.setState({
          visible: false
        });
    };

    switchEvaluableOnChange = (checked) => {
        this.setState((prevState) => ({
            evaluable: checked,
        }));
    };

    switchPassedOnChange = (checked) => {
        this.setState((prevState) => ({
            passed: checked,
        }));
    };

    onFinish = (values) => {
        values = {evaluable: this.state.evaluable, passed: this.state.passed};
        console.log(this.props.level.user_level_id)
        const url = `/api/v1/levels/${this.props.level.user_level_id}/level_modify_status`;
        fetch(url, {
            method: "put",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      
            },
            body: JSON.stringify(values),
          })
            .then((data) => {
              if (data.ok) {
                this.handleCancel();
                this.formRef.current.resetFields(); 
                return data.json();
              }
              throw new Error("Network error.");
            })
            .then((data) => {
                this.props.reloadLevels();
                notification.open({
                        message: 'Estado modificado correctamente!',
                        placement: 'bottom',
                        icon: <EditOutlined style={{ color: '#394088' }} />,
                        duration: 4
                      });
            })
            .catch((err) => console.error("Error: " + err));
    };


    render() {

    const title = "Modificar estado del nivel de " + this.props.level.name;

    return (
      <>
        <button className="primary-btn" type="primary" onClick={this.showModal} style={{"margin-right": "15px", "width":"auto", "border-radius":"10px"}}>
            <i className="bi bi-pencil-square"></i> Modificar
        </button>

        <Modal title={title} visible={this.state.visible} onCancel={this.handleCancel} footer={null}>
        <Form ref={this.formRef} layout="vertical" onFinish={this.onFinish} 
              initialValues={{
                                evaluable: this.props.evaluable,
                                passed: this.props.passed
                            }}>

            <Row gutter={16}>
                <Col className="gutter-row" span={12}>
                    {/* Titulo */}
                    <Form.Item name="evaluable" label="Su curso es evaluable?" rules={[{ required: false, message: "Por favor, introduce el nombre del curso que quieres añadir" }]}>
                        <Switch checkedChildren="Sí" unCheckedChildren="No" defaultChecked={this.props.level.evaluable} onChange={this.switchEvaluableOnChange} />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                    {/* Titulo */}
                    <Form.Item name="passed" label="Ha aprobado el curso?" rules={[{ required: false, message: "Por favor, introduce el nombre del curso que quieres añadir" }]}>
                        <Switch checkedChildren="Sí" unCheckedChildren="No" defaultChecked={this.props.level.passed} onChange={this.switchPassedOnChange} />
                    </Form.Item>
                </Col>
            </Row>       
           
            <Form.Item>
                <Button type="primary" htmlType="submit">
                Actualizar
                </Button>
                <Button type="danger" onClick={()=>this.handleCancel()}>
                Cancelar
                </Button>
            </Form.Item>
        </Form>
        </Modal>
      </>
    );
  }
}

export default EditLevelModal;