import React from "react";
import { Layout, Menu, Col, Row, Space } from "antd";
import UserProfile from "./UserProfile"
import logo from 'images/logo_white';

const { Header } = Layout;

export default () => (
	<Header className="hcit-header">
		<Row className="logo-and-user" gutter={36}>
			<Col className="gutter-row" xs={12} xl={4}>
				<img className="logo" src={logo} />	
			</Col>

			<Col className="gutter-row" xs={0} xl={14}>
			  
			</Col>

			<Col className="gutter-row" xs={12} xl={6}>
			  <UserProfile/>
			</Col>
		</Row>
	</Header>
);
