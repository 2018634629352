import React from "react";
import { NavLink } from "react-router-dom";

class VerticalNav extends React.Component {

	state = {
		userRole: [],
	  };

	componentDidMount() {
		this.getUserCondition();
	}

	navBarActiveButton = (buttonId) => {
		div = document.getElementsByClassName("ant-layout-sider-children")[0];
		buttons = div.getElementsByTagName("button");

		buttons.forEach(button => {
			button.classList.remove("active");
		});

		document.getElementById(buttonId).classList.add("active");
	};

	
	handleLogout = () => {
		const url = "/users/sign_out";
		fetch(url, {
			method: "delete",
		})
		.then((data) => {
			if (data.ok) {
				window.location.href = '/';
				return data.json();
			}
			throw new Error("Network error.");
		})
		.catch((err) => message.error("Error: " + err));
	};
	
	getUserCondition = () => {
		const url = "/api/v1/users/get_user_condition";
		fetch(url, {
		  headers: {
			Accept: "application/json, text/plain, */*",
			Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
		  },})
		  .then((data) => {
			if (data.ok) {
			  return data.json();
			}
			throw new Error("Network error.");
		  }).then((data) => {
			this.setState({ userRole: data});
		  })
		  .catch();
	};


	render() {

		let buttons;

		if (this.state.userRole.notice != undefined) {
			if(this.state.userRole.notice == "admin") {
				buttons = 
				<>
				<NavLink to="/training_centers">
				<button id="centers" className="nav-button">
					<i className="bi bi-bank2"></i> Centros		
					</button>
				</NavLink>

				<NavLink to ="/courses">
					<button id="courses" className="nav-button">
						<i className="bi bi-laptop"></i> Cursos		
					</button>
				</NavLink>

				
				<NavLink to ="/users">
					<button id="usuarios" className="nav-button">
						<i className="bi bi-people-fill"></i> Usuarios		
					</button>
				</NavLink>
				</>;
				
			} else if(this.state.userRole.notice == "training_center_admin"){
				buttons = 
				<>
				<NavLink to ="/courses">
					<button id="courses" className="nav-button">
						<i className="bi bi-laptop"></i> Cursos		
					</button>
				</NavLink>
				<NavLink to ="/users">
					<button id="usuarios" className="nav-button">
						<i className="bi bi-people-fill"></i> Usuarios		
					</button>
				</NavLink>
				</>;
			} else if(this.state.userRole.notice == "student"){
				buttons = 
				<>
				<NavLink to ="/courses">
					<button id="courses" className="nav-button">
						<i className="bi bi-laptop"></i> Cursos		
					</button>
				</NavLink>
				</>;
			} else if(this.state.userRole.notice == "instructor"){
				buttons = 
				<>
				<NavLink to ="/courses">
					<button id="courses" className="nav-button">
						<i className="bi bi-laptop"></i> Cursos		
					</button>
				</NavLink>
				</>;
			} else {
				buttons = 
				<>
				<button id="usuarios" className="nav-button">
					<i className="bi bi-people-fill"></i> Usuarios		
				</button>
				</>;
			}
		}

		return(
			<>
			{buttons}
			<hr/>

			<NavLink to ="/" className="logout">
				<button onClick={this.handleLogout} className="nav-button">
					<i className="bi bi-box-arrow-right"></i> Cerrar sesión		
				</button>
			</NavLink>			

			<div className="copyright">
				<p className="bold">© Healthcare Digital School - 2023</p>
				<p>Todos los derechos reservados</p>
			</div>
			</>
		);
	}
}

export default VerticalNav;