import { Button, Result } from 'antd';
import React from 'react';

class PageNotFound_404 extends React.Component {




handleLogout = () => {
  const url = "/users/sign_out";
  fetch(url, {
    method: "delete",
  })
  .then((data) => {
    if (data.ok) {
      window.location.href = '/users/sign_in';
      return data.json();
    }
    throw new Error("Network error.");
  })
  .catch((err) => message.error("Error: " + err));
};

render(){
  return(
  
  
  <>
  <Result
    status="404"
    title="404"
    subTitle="Lo siento, la pagina que buscas no existe."
    extra={[
          <Button href="/" type="primary">Volver</Button>
          ] }
  />
  
  </>
  );

}


}
export default PageNotFound_404;