import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import EditTrainingCenterModal from "../components/training_centers/EditTrainingCenterModal";
import TrainingCentersShow from "../components/training_centers/TrainingCentersShow";

export default (
  <Router>
      <Route path="/training_centers/:id/edit" element={<EditTrainingCenterModal />} />
      <Route path="/api/v1/training_centers/:id" element={<TrainingCentersShow />} />
  </Router>
);