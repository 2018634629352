import { Row, Col, Table, message, Popconfirm, Button, Switch, notification, Input, Search} from "antd";
import React from "react";
import { Link } from "react-router-dom";
import AddUserModal from "../users/AddUserModal";
import EditUserModal from "../users/EditUserModal";
import { DeleteOutlined } from '@ant-design/icons';
import EditLicenseModal from "./EditLicenseModal";

class CoursesLevels extends React.Component {

  constructor(props) {
    super(props);
  }

  state = {
    levels: [],
    userRole: [],
    loading: false
  };

  columns = [
    {
      title: "Nombre" ,
      dataIndex: "name",
      className: "table-name",
      render: (text, record) => (
        <> 
          <Link to={"/level/"+record.id}>{record.name}</Link>
          <i className="mobile bi bi-chevron-right"></i>
        </>
      ),
      sorter: (a, b) => { return a.name.localeCompare(b.name)},
    },
    {
      title: "Descripción" ,
      dataIndex: "description",
      sorter: (a, b) => { return a.description.localeCompare(b.description)},
    },
    {
      title: "¿Es asociado a un instructor?",
      dataIndex: "is_instructor",
      key: "is_instructor",
      render: (text, record) => (
        <>
          {record.is_instructor
            ? <>
                <span>Sí</span>
              </>
            : <>
                <span>No</span>
              </>
          }
        </>
      ),
    }
  ];

  UNSAFE_componentWillMount() {
    this.loadLevels(window.location.pathname.split("/").pop());
    this.getUserCondition();
  }

  reloadLevels = () => {
    this.setState({ levels: [] });
    this.loadUsers(this.props.course.id);
  };

  getUserCondition = () => {
    const url = "/api/v1/users/get_user_condition";
    fetch(url, {
      headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
      .then((data) => {
      if (data.ok) {
        return data.json();
      }
      throw new Error("Network error.");
      }).then((data) => {
      this.setState({ userRole: data});
      })
      .catch();
  };

  loadLevels = (id) => {
    console.log(id)
    const url = `/api/v1/courses/${id}/get_course_levels`;
    fetch(url, {
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },
    }).then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then((data) => {
        data.forEach((level) => {
          const newEl = {
            key: level.id,
            id: level.id,
            name: level.name,
            description: level.description,
            is_instructor: level.is_instructor
          };

          this.setState((prevState) => ({
            levels: [...prevState.levels, newEl],
            loading: false,
          }));
        });
      })
      .catch((err) => message.error("Error 3: " + err));
  };

  search = (evt) => {
    var currentValue = evt.target.value;
    console.log("SearchBar => "+currentValue);
    if (currentValue != "") {
      this.setState({ users: this.state.levels.filter(
        user => user.email.toUpperCase().includes(currentValue.toUpperCase())) 
      });
    } else {
      this.setState({ users: [] });
      this.loadLevels(this.props.course.id);
    }
  };

  render() {
    return (
      <>
        <Row gutter={16} className="top-row">
          <Col className="gutter-row" xl={8}>
           <h1 style={{"display":"block"}}>Niveles</h1>
          </Col>
          <Col className="gutter-row text-end search-col" xl={12}>
          <div className="search-bar">
            <button className="search-btn">
              <i className="bi bi-search"></i>
            </button>
            <input ref={ref => this.usersSearchBar = ref} id="cursos-search-bar" onChange={this.search} type="text" className="search-input" placeholder="Buscar..." />
          </div>
          </Col>
        </Row>

        <Table
          className="table-striped-rows"
          dataSource={this.state.levels}
          columns={this.columns}
          pagination={{ pageSize: 4 }}
          locale={{ 
            triggerDesc: 'Orden ascendente',
            triggerAsc: 'Orden descendente', 
            cancelSort: 'Mostrar sin ordenar'
          }}
        />
      </>
    );
  }
}

export default CoursesLevels;
