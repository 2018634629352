import { Row, Col, Table, message, notification ,Popconfirm, Button, Tooltip } from "antd";
import React from "react";
import moment from 'moment'
import { Link } from 'react-router-dom';
import AddCourseModal from "./AddCourseModal";
import EditCourseModal from "./EditCourseModal";
import SearchBar from "components/SearchBar";
import { DeleteOutlined } from '@ant-design/icons';
import CoursesSpecialists from "./CoursesSpecialists";
import { faCloudShowersHeavy } from "@fortawesome/free-solid-svg-icons";

class Courses extends React.Component {
  columns = [];

  state = {
    courses: [],
    userRole: [],
    userLogin: [],
    levels: [],
    user: null,
  };

  componentDidMount() {
    this.loadCourses();
    this.getUserCondition();
    this.getUserId();
    //this.loadLevels();
    //this.getUserLogin();
  }

  componentDidUpdate(prevProps) {
    this.setApprovals(this.state.courses);
  }

  loadCourses = () => {
    const url = "api/v1/courses";
    fetch(url, {
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },
    }).then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then((data) => {
        data.forEach((course) => {
          const newEl = {
            key: course.id,
            id: course.id,
            name: course.name,
            description: (course.description == null ? "" : course.description),
            category: course.category,
            level_1: course.level_1,
            level_2: course.level_2,
            level_3: course.level_3,
            modality: course.modality,
            format: course.format,
            aval: course.aval,
            subjects: (course.subjects == null ? "" : course.subjects),
            id_course_moodle: course.id_course_moodle,
            id_evaluation_moodle: course.id_evaluation_moodle,
            license_number: course.license_number,
            licenses: course.licenses,
            evaluable: course.evaluable,
            passed: course.passed,
            created_at: course.created_at,
            updated_at: course.updated_at,
            training_center_ids: course.training_center_ids,
            specialists_ids: course.specialist_ids,
            moodle_link: course.moodle_link,
            evaluation_link: course.moodle_evaluation_link,

          };

          this.setState((prevState) => ({
            courses: [...prevState.courses, newEl],
          }));
        });
      })
      .catch((err) => message.error("Error: " + err));
  };

  setApprovals = (courses) => {
    for (let index in courses) {
      if (courses[index] != null && this.state.user != null) {
        this.checkApproval(courses[index].id, this.state.user.id);
      }
    }
  };

  checkApproval = (level_id,user_id) => {
    const url = `/api/v1/levels/check_approvals/${level_id}/${user_id}`;
    fetch(url, {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },
    })
  };

  reloadCourses = () => {
    this.setState({ courses: [] });
    this.loadCourses();
    //this.getUserMoodleCourseLink();
  };

  deleteCourse = (id) => {
    const url = `api/v1/courses/${id}/destroy_course`;
    fetch(url, {
      method: "delete",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
      .then((data) => {
        if (data.ok) {
          this.reloadCourses();
          return data.json();
        }
        throw new Error("Network error.");
      }).then(() => {
        this.cursosSearhBar.value = "";
        notification.open({
                  message: 'Curso eliminado correctamente!',
                  placement: 'bottom',
                  icon: <DeleteOutlined style={{ color: '#ff3232'}} />,
                  duration: 4
                });
      })
      .catch((err) => message.error("Error: " + err));
  };

  loadCurrentStudentCoursesStatus = () => {
    
  };


  search = (evt) => {
    var currentValue = evt.target.value;
    console.log("SearchBar => "+currentValue);
    if (currentValue != "") {
      this.setState({ courses: this.state.courses.filter(
        courses => courses.name.toUpperCase().includes(currentValue.toUpperCase())) 
      });
    } else {
      this.setState({ courses: [] });
      this.loadCourses();
    }
  };

  getUserCondition = () => {
		const url = "api/v1/users/get_user_condition";
		fetch(url, {
		  headers: {
			Accept: "application/json, text/plain, */*",
			Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
		  },})
		  .then((data) => {
			if (data.ok) {
			  return data.json();
			}
			throw new Error("Network error.");
		  }).then((data) => {
			this.setState({ userRole: data});
		  })
		  .catch();
	};

  getUserId = () => {
		const url = "api/v1/users/get_user_id";
		fetch(url, {
		  headers: {
			Accept: "application/json, text/plain, */*",
			Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
		  },})
		  .then((data) => {
			if (data.ok) {
			  return data.json();
			}
			throw new Error("Network error.");
		  }).then((data) => {
			this.setState({ user: data});
		  })
		  .catch();
	};

  getUserLogin = () => {
    const url = "api/v1/users/login_moodle";
    fetch(url, {
      headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
      .then((data) => {
      if (data.ok) {
        return data.json();
      }
      throw new Error("Network error.");
      }).then((data) => {
      this.setState({ userLogin: data});
      })
      .catch();
    };

  render() {

    //Add Course button condition
    let buttons;
    if (this.state.userRole.notice != undefined) {
      switch (this.state.userRole.notice) {
				case "training_center_admin":
					buttons = ""
					break;
				case "admin":
					buttons = <AddCourseModal reloadCourses={this.reloadCourses} />
					break;
				case "student":
					buttons = ""
					break;
        case "instructor":
          buttons = ""
          break;
				default:
					buttons = ""
					break;				
			}
      
    }

    //Edit and Destroy buttons condition
    let columns=[];
    if (this.state.userRole.notice != undefined) {
      if (this.state.userRole.notice == "admin") { 
        columns = [
          {
            title: "Nombre" ,
            dataIndex: "name",
            className: "table-name",
            render: (text, record) => (
              <>
              <Link to={"/course/"+record.id}>{record.name}</Link>
              </>
            ),
            sorter: (a, b) => { return a.name.localeCompare(b.name)},
          },
          {
            title: "Nº de alumnos inscritos",
            dataIndex: "description",
            render: (text, record) => (
            <>
              {record.license_number}
            </>
            )
          },
          // {
          //   title: "Enlaces",
          //   dataIndex: "links",
          //   render: (text, record) => (
          //   <>
          //     <a target={"_blank"} href={"https://campus.ihtc.io/course/view.php?id=" + record.id_course_moodle} className="secondary-btn px-3 py-2">
          //       <i className="bi bi-box-arrow-up-right"></i> Ver landing
          //     </a>
          //   </>
          //   )
          // },
          {
            title: "Acciones",
            key: "action",
            render: (_text, record) => (
              <>
              <EditCourseModal course = {record} reloadCourses={this.reloadCourses} /> 
              <Popconfirm
                title="Seguro que quiere borrar el curso?"
                onConfirm={() => this.deleteCourse(record.id)}
                okText="Si"
                cancelText="No"
              >
                <Button className="action-btn" href="#" type="danger">
                  <i className="bi bi-trash3"></i>
                </Button>
              </Popconfirm>
              </>
            ),
          }
        ];
      }
      else if (this.state.userRole.notice == "training_center_admin") {
        columns = [
          {
            title: "Nombre" ,
            dataIndex: "name",
            className: "table-name",
            render: (text, record) => (
              <>
              <Link to={"/course/"+record.id}>{record.name}</Link>
              </>
            ),
            sorter: (a, b) => { return a.name.localeCompare(b.name)},
          },
          {
            title: "Nº de alumnos inscritos",
            dataIndex: "description",
            render: (text, record) => (
            <>
              {record.license_number}
            </>
            )
          },
          {
            title: "Fecha de creación",
            dataIndex: "created_at",
            render: (text, record) => (
            <>
              {moment(record.created_at).format("DD/MM/YYYY")}
            </>
            )
          },
          {
            title: "Fecha de actualización",
            dataIndex: "updated_at",
            render: (text, record) => (
            <>
              {moment(record.updated_at).format("DD/MM/YYYY")}
            </>
            )
          },
          // {
          //   title: "Enlaces",
          //   dataIndex: "links",
          //   render: (text, record) => (
          //     <>
          //     <a target={"_blank"} href={"https://campus.ihtc.io/course/view.php?id=" + record.id_course_moodle} className="secondary-btn px-3 py-2">
          //       <i className="bi bi-box-arrow-up-right"></i> Ver landing
          //     </a>
          //   </>
          //   )
          // }
        ];
      }
      else if (this.state.userRole.notice == "student"){
        columns = [
          {
            title: "Nombre" ,
            dataIndex: "name",
            render: (text, record) => (
              <>
                {record.name}
                <i className="mobile bi bi-chevron-right"></i>
              </>
            ),
            sorter: (a, b) => { return a.name.localeCompare(b.name)},
          },
          {
            title: "Estado",
            dataIndex: "passed",
            render: (text, record) => (
              <>
              {record.evaluable
                ? <>
                    <span className="success-chip"><i className="bi bi-check-circle-fill"></i> Evaluable</span>
                  </>
                : <>
                    <span className="fail-chip"><i className="bi bi-x-circle-fill"></i> No evaluable</span>
                  </>
              }
               {record.passed
                ? <>
                    <span className="success-chip"><i className="bi bi-check-circle-fill"></i> Aprobado</span>
                  </>
                : <>
                    <span className="fail-chip"><i className="bi bi-x-circle-fill"></i> No aprobado</span>
                  </>
              }
              </>
            ),
          },               
          {
            title: "Acciones",
            key: "action",
            render: (_text, record) => (
              <>

              <Tooltip placement="top" title="Enlace a moodle">
                <a style={{ marginRight: "5px" }} href={record.moodle_link} target={"_blank"} className="primary-btn px-3 py-2">
                  <i className="bi bi-box-arrow-up-right"></i> Ir al curso
                </a>
              </Tooltip>
                {record.evaluable
                ? <>
                   <a href={record.evaluation_link} target={"_blank"} className="secondary-btn px-3 py-2">
                    <i className="bi bi-pen"></i> Ir a la evaluación
                  </a>
                  </>
                : <></>
              }
              </>
            ),
          }          
        ];
      }
      else if (this.state.userRole.notice == "instructor"){
        columns = [
          {
            title: "Nombre" ,
            dataIndex: "name",
            render: (text, record) => (
              <>
                {record.name}
                <i className="mobile bi bi-chevron-right"></i>
              </>
            ),
            sorter: (a, b) => { return a.name.localeCompare(b.name)},
          },
          {
            title: "Estado",
            dataIndex: "passed",
            render: (text, record) => (
              <>
              {record.evaluable
                ? <>
                    <span className="success-chip"><i className="bi bi-check-circle-fill"></i> Evaluable</span>
                  </>
                : <>
                    <span className="fail-chip"><i className="bi bi-x-circle-fill"></i> No evaluable</span>
                  </>
              }
               {record.passed
                ? <>
                    <span className="success-chip"><i className="bi bi-check-circle-fill"></i> Aprobado</span>
                  </>
                : <>
                    <span className="fail-chip"><i className="bi bi-x-circle-fill"></i> No aprobado</span>
                  </>
              }
              </>
            ),
          },               
          {
            title: "Acciones",
            key: "action",
            render: (_text, record) => (
              <>

              <Tooltip placement="top" title="Enlace a moodle">
                <a style={{ marginRight: "5px" }} href={record.moodle_link} target={"_blank"} className="primary-btn px-3 py-2">
                  <i className="bi bi-box-arrow-up-right"></i> Ir al curso
                </a>
              </Tooltip>
                {record.evaluable
                ? <>
                   <a href={record.evaluation_link} target={"_blank"} className="secondary-btn px-3 py-2">
                    <i className="bi bi-pen"></i> Ir a la evaluación
                  </a>
                  </>
                : <></>
              }
              </>
            ),
          }          
        ];
      }
    }

    //Specialists
    let specialistsComponent;
    if (this.state.userRole.notice != undefined) {
      switch (this.state.userRole.notice) {
				case "training_center_admin":
					specialistsComponent = ""
					break;
				case "admin":
					specialistsComponent = <CoursesSpecialists/>
					break;
				case "student":
					buttons = ""
					break;
				default:
					specialistsComponent = ""
					break;				
			}
    }
      
    
    return (
      
      <>
        <Row gutter={16} className="top-row">
            <Col className="gutter-row" xl={8}>
             <h1>Cursos</h1>
            </Col>
            <Col className="gutter-row text-end search-col" xl={12}>
             <div className="search-bar">
                <button className="search-btn">
                  <i className="bi bi-search"></i>
                </button>
                <input ref={ref => this.cursosSearhBar = ref} id="cursos-search-bar" onChange={this.search} type="text" className="search-input" placeholder="Buscar..." />
              </div>
            </Col>
            <Col className="gutter-row" xl={4}>
              {buttons}
            </Col>
          </Row>
        <Table
          className="table-striped-rows"
          dataSource={this.state.courses}
          columns={columns}
          pagination={{ pageSize: 7 }}
          locale={{ 
            triggerDesc: 'Orden ascendente',
            triggerAsc: 'Orden descendente', 
            cancelSort: 'Mostrar sin ordenar'
          }}
        />
        {specialistsComponent}
      </>
    );
  }
}

export default Courses;
