import { Button, Form, Input, Modal, Select, notification, Col, Space, Checkbox, List } from "antd";
import { CheckCircleOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import React from "react";

const { Option } = Select;

class AddTrainingCenterModal extends React.Component {
  formRef = React.createRef();
  state = {
    visible: false,
    levels: []
  };

  onFinish = (values) => {
    const url = "api/v1/training_centers/create_training_center";
    fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"

      },
      body: JSON.stringify(values),
    })
      .then((data) => {
        if (data.ok) {
          this.handleCancel();
          this.formRef.current.resetFields();
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then(() => {
        this.props.reloadTrainingCenters();
        notification.open({
                  message: 'Centro añadido correctamente!',
                  placement: 'bottom',
                  icon: <CheckCircleOutlined style={{ color: '#4BDAB1' }} />,
                  duration: 4
                });

      })
      .catch((err) => console.error("Error: " + err));
  };

  componentDidMount() {
    this.getLevels();
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  getLevels = () => {
    const url = `/api/v1/levels`;
    fetch(url, {
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
    .then((data) => {
      if (data.ok) {
        return data.json();
      }
      throw new Error("Network error.");

    }).then((data) => {
      this.setState({
        levels: data.map(item => ({
          id: item.id,
          name: item.name,
          training_center_number: item.training_center_number,
          id_course_level_moodle: item.id_course_level_moodle,
          id_evaluation_level_moodle: item.id_evaluation_level_moodle,
          is_instructor: item.is_instructor,
          course_name: item.course_name
        }))   
      }); 
    })
    .catch();
  };

  render() {
    return (
      <>
        <button className="primary-btn" type="primary" onClick={this.showModal}>
          <i className="bi bi-plus-lg"></i> Nuevo Centro
        </button>

        <Modal title="Añade un nuevo centro formativo ..." visible={this.state.visible} onCancel={this.handleCancel} footer={null} width={1000} style={{ top: 20 }}>
          <Form ref={this.formRef} layout="vertical" onFinish={this.onFinish}>

            <Form.Item name="name" label="Nombre del centro" rules={[{ required: true, message: "Por favor, introduce el nombre del centro formativo" }]}>
              <Input placeholder="Nombre del centro formativo" />
            </Form.Item>

            <Form.Item name="training_center_number" label="Código del centro" rules={[{ required: true, message: "Por favor, introduce el código del centro formativo" }]}>
              <Input placeholder="Código del centro formativo" />
            </Form.Item>

            <Form.Item name="training_center_name" label="Nombre del administrador de centro" rules={[{ required: true, message: "Por favor, introduce el nombre del administrador del centro formativo" }]}>
              <Input placeholder="Nombre del administrador de centro" />
            </Form.Item>

            <Form.Item name="training_center_surname" label="Apellidos del administrador de centro" rules={[{ required: true, message: "Por favor, introduce los apellidos del administrador del centro formativo" }]}>
              <Input placeholder="Apellidos del administrador de centro" />
            </Form.Item>

             <Form.Item name="training_center_email" label="Email del administrador de centro" rules={[{ required: true, message: "Por favor, introduce un email del administrador del centro formativo" }]}>
              <Input placeholder="Email del administrador de centro" />
            </Form.Item>
            <Col className="gutter-row" span={24}>
              <Form.List name="levels" initialValue={this.state.levels} >
                {(fields, { ...restField }) => (
                  <>
                    {fields.map((field, index) => (
                      <Space key={field.key} style={{ display: "flex", marginBottom: 0 }} align="baseline"> 
                        <p>{this.state.levels[index].course_name + " (" + this.state.levels[index].name + ")"}</p>
                        <Form.Item
                          {...restField}
                          name={[field.name, "number_of_licenses"]}
                          fieldKey={[field.fieldKey, "number_of_licenses"]}
                        >
                          <Input placeholder="Número de licencias para este nivel" />
                        </Form.Item>
                      </Space>  
                    ))}
                  </>
                )}
              </Form.List>
            </Col>            
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Insertar
              </Button>
              <Button type="danger" onClick={()=>this.handleCancel()}>
                Cancelar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default AddTrainingCenterModal;