import { Button, Form, Input, Modal, Select, Row, Col, notification, Checkbox, List, Space} from "antd";
import { CheckCircleOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import TrainingCenters from "components/training_centers/TrainingCenters";
import React, { Component, useState } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const { Option } = Select;
const { TextArea } = Input;
const files = [];

class AddCourseModal extends React.Component {
  formRef = React.createRef();

  state = {
    visible: false,
    inputDescription: "",
    inputAuthors: "",
    inputSubjects: "",
    descriptionImage: "",
    courseIcon: "",
    levelImages: [],
    sponsorImages: [],
  };


  onImageDescriptionUpload = (course_id) => {
    //UpdateImage
    const urlDescImg = `api/v1/courses/${course_id}/attach_description_image_to_course`;
    let formData = new FormData()
    formData.append('description_image', this.state.descriptionImage)
    fetch(urlDescImg, {
        method: 'PUT',
        body: formData
    })
    //.then(resp => resp.json())
    .then(data => {
        if (data.errors) {
          alert(data.errors)
        }
        else {
          console.log(data)
        }
    })
  }

  onImageSponsorUpload = (sponsors) => {
    //UpdateImage
    for (let index in sponsors) {
      let sponsor_id = sponsors[index].id
      const urlSponsorImg = `api/v1/sponsors/${sponsor_id}/attach_image_to_sponsor`;
      let formData = new FormData()
      formData.append('sponsor_image', this.state.sponsorImages[index])
      fetch(urlSponsorImg, {
          method: 'PUT',
          body: formData
      })
      .then(resp => resp.json())
      .then(data => {
        if (data.errors) {
          alert(data.errors)
        }
        else {
          console.log(data)
        }
      })
    }
  }

  onImageLevelUpload = (levels) => {
    //UpdateImage
    for (let index in levels) {
      let level_id = levels[index].id
      const urlLevelImg = `api/v1/levels/${level_id}/attach_image_to_level`;
      let formData = new FormData();
      formData.append('level_image', this.state.levelImages[index])
      fetch(urlLevelImg, {
        method: 'PUT',
        body: formData
      })
      //.then(resp => resp.json())
      .then(data => {
        if (data.errors) {
          alert(data.errors)
        }
        else {
          console.log(data)
        }
      })
    }
  }

  onCourseIconUpload = (course_id) => {
    //UpdateImage
    const urlDescImg = `api/v1/courses/${course_id}/attach_course_icon_to_course`;
    let formData = new FormData()
    formData.append('course_icon', this.state.courseIcon)
    fetch(urlDescImg, {
        method: 'PUT',
        body: formData
    })
    .then(resp => resp.json())
    .then(data => {
        if (data.errors) {
          alert(data.errors)
        }
        else {
          console.log(data)
        }
    })
  }

  onFinish = (values) => {
    values = { ...values, description: this.state.inputDescription, authors: this.state.inputAuthors, subjects: this.state.inputSubjects, description_image: this.state.descriptionImage};

    const url = "api/v1/courses/create_course";
    fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"

      },
      body: JSON.stringify(values),
    })
      .then((data) => {
        if (data.ok) {
          this.handleCancel();
          this.formRef.current.resetFields();          
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then((data) => {
        this.props.reloadCourses();
        this.onImageDescriptionUpload(data.id);
        this.onCourseIconUpload(data.id);
        this.onImageLevelUpload(data.levels);
        this.onImageSponsorUpload(data.sponsors);

        notification.open({
                  message: 'Curso añadido correctamente!',
                  placement: 'bottom',
                  icon: <CheckCircleOutlined style={{ color: '#4BDAB1' }} />,
                  duration: 4
                });
      })
      .catch((err) => console.error("Error: " + err));                  
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
    this.resetForm();
  };

  handleCancel = () => {
    this.setState({visible: false});
  };

  resetForm = () => {
    this.formRef.current.resetFields();
    this.setState({
      inputDescription: "",
      inputAuthors: "",
      inputSubjects: "",
      descriptionImage: null,
      courseIcon: null,
      levelImages: [],
      sponsorImages: [],
    });
  }

  componentDidMount() {
    this.loadTrainingCenters();
    this.loadSpecialists();
  }

  onEditorStateDescriptionChange = (editorStateDescription) => {
    this.setState((prevState) => ({
      editorStateDescription,
      inputDescription: draftToHtml(convertToRaw(editorStateDescription.getCurrentContent())),
    }));
  };

  onEditorStateSubjectsChange = (editorStateSubjects) => {
    this.setState((prevState) => ({
      editorStateSubjects,
      inputSubjects: draftToHtml(convertToRaw(editorStateSubjects.getCurrentContent())),
    }));
  };

  state = {
    training_centers: [],
    specialists: [],
    editorStateDescription: EditorState.createEmpty(),
    editorStateSubjects: EditorState.createEmpty(),
  };

  loadTrainingCenters = () => {
    const url = "api/v1/training_centers";
    fetch(url, {
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },
    }).then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then((data) => {
        data.forEach((training_center) => {
          const newEl = {
            key: training_center.id,
            id: training_center.id,
            name: training_center.name,
            training_center_email: training_center.users.email
          };

          this.setState((prevState) => ({
            training_centers: [...prevState.training_centers, newEl],
          }));
        });
      })
      .catch((err) => message.error("Error: " + err));
  };

  loadSpecialists = () => {
    const url = "api/v1/specialists";
    fetch(url, {
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },
    }).then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then((data) => {
        data.forEach((specialist) => {
          const newEl = {
            key: specialist.id,
            id: specialist.id,
            name: specialist.name,
          };

          this.setState((prevState) => ({
            specialists: [...prevState.specialists, newEl],
          }));
        });
      })
      .catch((err) => message.error("Error: " + err));
  };

  selectItems = (trainingcenters) => {
    return trainingcenters.map(function (training_center) {
      return (
         <Option value={training_center.id}>{training_center.name}</Option>
      )
    })
  };

  selectItemsSpecialists = (specialists) => {
    return specialists.map(function (specialist) {
      return (
         <Option value={specialist.id}>{specialist.name}</Option>
      )
    })
  };

  onImageDescriptionChange = (e) => {
    var file = e.target.files[0];
    this.setState((prevState) => ({
      descriptionImage: file,
    }));
  }

  onImageCourseIconChange = (e) => {
    var file = e.target.files[0];
    this.setState((prevState) => ({
      courseIcon: file,
    }));
  }

  onImageLevelChange = (e) => {
    var file = e.target.files[0];
    if(!this.state.levelImages){
      this.setState({levelImages: [file,]});
    } else {
      this.setState({levelImages: [...this.state.levelImages, file]})
    }
  }

  onImageSponsorChange = (e) => {
    var file = e.target.files[0];
    if(!this.state.sponsorImages){
      this.setState({sponsorImages: [file,]});
    } else {
      this.setState({sponsorImages: [...this.state.sponsorImages, file]})
    }
  }

  render() {
    const { editorStateDescription } = this.state;
    const { editorStateAuthors } = this.state;
    const { editorStateSubjects } = this.state;

    return (
      <>
        <button className="primary-btn" type="primary" onClick={this.showModal}>
          <i className="bi bi-plus-lg"></i> Nuevo Curso
        </button>

        <Modal title="Añade un nuevo curso ..." visible={this.state.visible} onCancel={this.handleCancel} footer={null} width={1000} style={{ top: 20 }}>
          <Form ref={this.formRef} layout="vertical" onFinish={this.onFinish}>

            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                {/* Titulo */}
                <Form.Item name="name" label="Nombre del curso" rules={[{ required: true, message: "Por favor, introduce el nombre del curso que quieres añadir" }]}>
                  <Input placeholder="Nombre del curso a añadir" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                {/* Categoria */}
                <Form.Item name="category" label="Categoria" rules={[{ required: true, message: "Por favor, introduce la categoria del curso" }]}>
                  <Input placeholder="Categoria del curso" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={4}>
                {/* ID MOODLE */}
                <Form.Item name="id_course_moodle" label="ID curso en Moodle" rules={[{ required: true, message: "Por favor, introduce el id del curso en Moodle" }]}>
                  <Input placeholder="ID curso en Moodle" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                {/* Icono Curso */}
                <Form.Item name="courseIcon" label="Selecciona el icono superior" rules={[{ required: true, message: "Por favor, introduce la categoria del curso" }]}>
                  <input type="file" accept="image/*" onChange={this.onImageCourseIconChange} />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={10}>
                {/* Imagen descripcion */}
                <Form.Item name="descImg" label="Selecciona la foto de la descripción" rules={[{ required: true, message: "Por favor, introduce la categoria del curso" }]}>
                  <input type="file" accept="image/*" onChange={this.onImageDescriptionChange} />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                {/* Descripcion */}
                <Form.Item name="description" label="* Descripción" rules={[{ required: false, message: "Por favor, introduce la descripción del curso" }]}>
                  <Input style={{display: "none"}} value={draftToHtml(convertToRaw(editorStateDescription.getCurrentContent()))} />
                
                  <div className="wysiswyg-textarea">
                    <Editor
                    editorState={editorStateDescription}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={this.onEditorStateDescriptionChange}
                    toolbar=
                    {
                      {
                      options:['inline', 'link', 'list' ], 
                      inline: { options:['bold', 'italic', 'underline'] },
                      }
                    }
                  />
                                
                  </div>
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={24}>
                <Form.List name="levels">
                  {(fields, { add, remove, ...restField }) => (
                    <>
                      {fields.map((field) => (
                        <Space key={field.key} style={{ display: "flex", marginBottom: 8 }} align="baseline">  
                          <Form.Item
                            {...restField}
                            name={[field.name, "name"]}
                            fieldKey={[field.fieldKey, "name"]}
                            label={[field.label, "Nombre del nivel"]}
                          >
                            <Input placeholder="Nombre del nivel a añadir" />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            name={[field.name, "id_course_level_moodle"]}
                            fieldKey={[field.fieldKey, "id_course_level_moodle"]}
                            label={[field.label, "ID del nivel en Moodle"]}
                          >
                            <Input placeholder="ID" />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            name={[field.name, "id_evaluation_level_moodle"]}
                            fieldKey={[field.fieldKey, "id_evaluation_level_moodle"]}
                            label={[field.label, "ID de la evaluación en Moodle"]}
                          >
                            <Input placeholder="ID" />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            name={[field.name, "levelImg"]}
                            fieldKey={[field.fieldKey, "levelImg"]}
                            label={[field.label, "Selecciona la foto del nivel"]}
                          >
                            <input type="file" accept="image/*" onChange={this.onImageLevelChange} />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            name={[field.name, "is_instructor"]}
                            fieldKey={[field.fieldKey, "is_instructor"]}
                            valuePropName="checked"
                          >                          
                            <Checkbox>¿Es instructor?</Checkbox>
                          </Form.Item>
                          
                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                        </Space>  
                        ))}

                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Añadir nivel
                          </Button>
                        </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col> 

              <Col className="gutter-row" span={12}>
                {/* Modalidad */}
                <Form.Item name="modality" label="Modalidad" rules={[{ required: true, message: "Por favor, introduce la Modalidad" }]}>
                  <Input placeholder="Modalidad del curso" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                {/* Formato */}
                <Form.Item name="format" label="Formato" rules={[{ required: true, message: "Por favor, introduce del Formato" }]}>
                  <Input placeholder="Formato del curso" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={24}>
                <Form.List name="sponsor">
                  {(fields_sponsor, { add, remove, ...restFieldSponsor }) => (
                    <>
                      {fields_sponsor.map((field_sponsor) => (
                        <Space key={field_sponsor.key} style={{ display: "flex", marginBottom: 8 }} align="baseline">  
                          <Form.Item
                            {...restFieldSponsor}
                            name={[field_sponsor.name, "name"]}
                            fieldKey={[field_sponsor.fieldKey, "name"]}
                            label={[field_sponsor.label, "Nombre del avalador"]}
                          >
                            <Input placeholder="Nombre del avalador a añadir" />
                          </Form.Item>

                          <Form.Item
                            {...restFieldSponsor}
                            name={[field_sponsor.name, "sponsorImg"]}
                            fieldKey={[field_sponsor.fieldKey, "sponsorImg"]}
                            label={[field_sponsor.label, "Selecciona la foto del avalador"]}
                          >
                            <input type="file" accept="image/*" onChange={this.onImageSponsorChange} />
                          </Form.Item>          
                          <MinusCircleOutlined onClick={() => remove(field_sponsor.name)} />
                        </Space>  
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Añadir avalador
                          </Button>
                        </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col> 
              
              <Col className="gutter-row" span={24}>
                {/* Especialistas */}
                <Form.Item name="specialists_ids" label="Selecciona los especialistas que participan" rules={[{ required: true, message: "Por favor, selecciona uno o varios centros formativos del curso" }]}>
                  <Select 
                    placeholder="Selecciona uno o varios centros formativos"
                    mode="multiple"
                  >
                    {this.selectItemsSpecialists(this.state.specialists)}
                  </Select>  
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={24}>
                {/* Temario */}
                <Form.Item name="subjects" label="Temario" rules={[{ required: false, message: "Por favor, introduce el Temario que contiene el curso" }]}>
                  <Input style={{display: "none"}} value={draftToHtml(convertToRaw(editorStateSubjects.getCurrentContent()))} />
                    
                    <div className="wysiswyg-textarea">
                      <Editor
                      editorState={editorStateSubjects}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      onEditorStateChange={this.onEditorStateSubjectsChange}
                      toolbar=
                      {
                        {
                        options:['inline', 'link', 'list' ], 
                        inline: { options:['bold', 'italic', 'underline'] },
                        }
                      }
                    />
                                  
                    </div>
                </Form.Item>
              </Col>
              {/*<Col className="gutter-row" span={24}>
                <Form.Item name="training_center_ids" label="Centros formativos relacionados" rules={[{ required: true, message: "Por favor, selecciona uno o varios centros formativos del curso" }]}>
                  <Select 
                    placeholder="Selecciona uno o varios centros formativos"
                    mode="multiple"
                  >
                    {this.selectItems(this.state.training_centers)}
                  </Select>  
                </Form.Item>
              </Col>*/}
            </Row>       
                     
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Insertar
              </Button>
              <Button type="danger" onClick={()=>this.handleCancel()}>
                Cancelar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default AddCourseModal;