import React from "react";
import { Menu, Dropdown, Col, Row, Space } from "antd";
import userPhoto from 'images/user_photo';

const menu = (
	<Menu
		items={[
		{
			key: '1',
			label: (
			<button onClick={handleLogout} className="session-destroy-btn">
				<i className="bi bi-box-arrow-right"></i> Cerrar sesión		
			</button>
			),
		},
		]}
	/>
);

function handleLogout() {
	const url = "/users/sign_out";
		fetch(url, {
			method: "delete",
		})
		.then((data) => {
			if (data.ok) {
				window.location.href = '/';
				return data.json();
			}
			throw new Error("Network error.");
		})
		.catch((err) => message.error("Error: " + err));
};

class UserProfile extends React.Component {

	state = {
		userRole: [],

	  };

	componentDidMount() {
		this.getUserCondition();
		this.getUsername();
	}

	getUserCondition = () => {
		const url = "/api/v1/users/get_user_condition";
		fetch(url, {
		  headers: {
			Accept: "application/json, text/plain, */*",
			Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
		  },})
		  .then((data) => {
			if (data.ok) {
			  return data.json();
			}
			throw new Error("Network error.");
		  }).then((data) => {
			this.setState({ userRole: data});
		  })
		  .catch();
	};

	getUsername = () => {
		const url = "/api/v1/users/get_username";
		fetch(url, {
		  headers: {
			Accept: "application/json, text/plain, */*",
			Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
		  },})
		  .then((data) => {
			if (data.ok) {
			  return data.json();
			}
			throw new Error("Network error.");
		  }).then((data) => {
			this.setState({ userName: data});
		  })
		  .catch();
	};


	
	render() {

			var role = "";
			var name_surname = "";
			
			if (this.state.userName){
				name_surname = this.state.userName.notice;
			}else {
				name_surname = "desconocido";
			}
			
			switch (this.state.userRole.notice) {
				case "training_center_admin":
					role = "Administrador del centro";
					break;
				case "admin":
					role = "Administrador";
					break;
				case "student":
					role = "Estudiante";
					break;
				case "instructor":
					role = "Instructor";
					break;
				default:
					role = "No identificado";
					break;				
			}
		
		return(						
			<>
				<Row className="user-profile">
				<Col span={18} className="left-side">
					<p className="user-name">{name_surname}</p>
					<p className="user-role">{role}</p>
				</Col>
				<Col span={6} className="right-side">
					<Dropdown overlay={menu} placement="bottom" arrow>
						<img className="user-photo" src={userPhoto} />
					</Dropdown>
				</Col>
				</Row>
			</>
		);
	}
}

export default UserProfile;