import { Row, Col, Table, message, notification ,Popconfirm, Button, Tooltip } from "antd";
import React from "react";
import { Link } from 'react-router-dom';
import AddTrainingCenterModal from "../training_centers/AddTrainingCenterModal";
import EditTrainingCenterModal from "../training_centers/EditTrainingCenterModal";
import SearchBar from "components/SearchBar";
import { DeleteOutlined } from '@ant-design/icons';

class CoursesTrainingCenters extends React.Component {
  columns = [];

  constructor(props) {
    super(props);
  }

  state = {
    training_centers: [],
    userRole: [],
    userLogin: [],
  };

  // componentDidMount() {
  //   this.getUserCondition();
  //   this.getUserLogin();
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.course !== this.props.course) {
      this.loadTrainingCenters(this.props.course.id);
    }
  }

  loadTrainingCenters = (id) => {
    const url = `/api/v1/courses/${id}/get_course_training_centers`;
    fetch(url, {
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },
    }).then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then((data) => {
        data.forEach((training_center) => {
          const newEl = {
            key: training_center.id,
            id: training_center.id,
            name: training_center.name,
            number_of_licenses_availables: training_center.licenses_availables,
          };

          this.setState((prevState) => ({
            training_centers: [...prevState.training_centers, newEl],
          }));
        });
      })
      .catch((err) => message.error("Error: " + err));
  };

  reloadTrainingCenters = () => {
    this.setState({ training_centers: [] });
    this.loadTrainingCenters(this.props.course.id);
  };

  deleteTrainingCenter = (id) => {
    const url = `api/v1/training_centers/${id}/destroy_training_center`;
    fetch(url, {
      method: "delete",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
      .then((data) => {
        if (data.ok) {
          this.reloadTrainingCenters();
          return data.json();
        }
        throw new Error("Network error.");
      }).then(() => {
        this.training_centersSearhBar.value = "";
        notification.open({
                  message: 'Centro eliminado correctamente!',
                  placement: 'bottom',
                  icon: <DeleteOutlined style={{ color: '#ff3232'}} />,
                  duration: 4
                });
      })
      .catch((err) => message.error("Error: " + err));
  };


  search = (evt) => {
    var currentValue = evt.target.value;
    console.log("SearchBar => "+currentValue);
    if (currentValue != "") {
      this.setState({ training_centers: this.state.training_centers.filter(
        training_centers => training_centers.name.toUpperCase().includes(currentValue.toUpperCase())) 
      });
    } else {
      this.setState({ training_centers: [] });
      this.loadTrainingCenters(this.props.course.id);
    }
    
  };

  getUserCondition = () => {
    const url = "/api/v1/users/get_user_condition";
    fetch(url, {
      headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
      .then((data) => {
      if (data.ok) {
        return data.json();
      }
      throw new Error("Network error.");
      }).then((data) => {
      this.setState({ userRole: data});
      })
      .catch();
  };

  getUserLogin = () => {
    const url = "/api/v1/users/login_moodle";
    fetch(url, {
      headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
      .then((data) => {
      if (data.ok) {
        return data.json();
      }
      throw new Error("Network error.");
      }).then((data) => {
      this.setState({ userLogin: data});
      })
      .catch();
  };



  render() {

    //Add Course button condition
    let buttons;
    if (this.state.userRole.notice != undefined) {
      switch (this.state.userRole.notice) {
        case "training_center_admin":
          buttons = ""
          break;
        case "admin":
          buttons = <AddTrainingCenterModal reloadTrainingCenters={this.reloadTrainingCenters} />
          break;
        case "student":
          buttons = ""
          break;
        case "instructor":
          buttons = ""
          break;
        default:
          buttons = ""
          break;        
      }
      
    }

    //Edit and Destroy buttons condition
    let columns=[];
    
    columns = [
      {
        title: "Nombre" ,
        dataIndex: "name",
        className: "table-name",
        render: (text, record) => (
          <>
            <Link to={"/training_center/"+record.id}>{record.name}</Link>
            <i className="mobile bi bi-chevron-right"></i>
            <EditTrainingCenterModal training_center = {record} reloadTrainingCenters={this.reloadTrainingCenters} /> 
            <Popconfirm
              title="Seguro que quiere borrar el centro?"
              onConfirm={() => this.deleteTrainingCenter(record.id)}
              okText="Si"
              cancelText="No"
            >
              <Button className="mobile action-btn" href="#" type="danger">
                <i className="bi bi-trash3"></i>
              </Button>
            </Popconfirm>
          </>
        ),
        sorter: (a, b) => { return a.name.localeCompare(b.name)},
      },
      {
        title: "Número de Licencias Utilizadas",
        dataIndex: "number_of_licenses_availables",
        key: "number_of_licenses_availables",
        sorter: (a, b) => a.number_of_licenses_availables - b.number_of_licenses_availables 
      },
      {
        title: "Acciones",
        key: "action",
        render: (_text, record) => (
          <>
          <EditTrainingCenterModal training_center = {record} reloadTrainingCenters={this.reloadTrainingCenters} /> 
          <Popconfirm
            title="Seguro que quiere borrar el centro?"
            onConfirm={() => this.deleteTrainingCenter(record.id)}
            okText="Si"
            cancelText="No"
          >
            <Button className="action-btn" href="#" type="danger">
              <i className="bi bi-trash3"></i>
            </Button>
          </Popconfirm>
          </>
        ),
      }
    ];
      
    return (
      <>
        <Row gutter={16} className="top-row">
            <Col className="gutter-row" xl={8}>
             <h1>Centros Formativos</h1>
            </Col>
            <Col className="gutter-row text-end search-col" xl={12}>
             <div className="search-bar">
                <button className="search-btn">
                  <i className="bi bi-search"></i>
                </button>
                <input ref={ref => this.training_centersSearhBar = ref} id="cursos-search-bar" onChange={this.search} type="text" className="search-input" placeholder="Buscar..." />
              </div>
            </Col>
            <Col className="gutter-row" xl={4}>
              {buttons}
            </Col>
          </Row>
        <Table
          className="table-striped-rows"
          dataSource={this.state.training_centers}
          columns={columns}
          pagination={{ pageSize: 4 }}
          locale={{ 
            triggerDesc: 'Orden ascendente',
            triggerAsc: 'Orden descendente', 
            cancelSort: 'Mostrar sin ordenar'
          }}
        />
      </>
    );
  }
}

export default CoursesTrainingCenters;
