import { Row, Col} from "antd";
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Courses from "../courses/Courses";
import defaultImg from 'images/default_center_img';
import LevelsUsers from "../levels/LevelsUsers";

class LevelsShow extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    levels: [],
    userLogin: [],
    userRole: [],
    number_of_licenses: ""
  };

  UNSAFE_componentWillMount() {
    this.loadLevel(window.location.pathname.split("/").pop());
    this.getUserCondition();
    this.getNumberOfLicensesLevel(window.location.pathname.split("/").pop());
    //this.getUserLogin();
  }

  loadLevel = (id) => {
    const url = `/api/v1/levels/${id}`;
    fetch(url, {
      method: "get",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
      .then((data) => {
      if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then((data) => {
        const singleLevel = {
          key: data.id,
          id: data.id,
          name: data.name,
          description: data.description,
          is_instructor: data.is_instructor
        };

        this.setState({
            ...this.state, // Not required but just a heads up on using mutation
            levels: singleLevel
        })
      })
     .catch((err) => message.error("Error: " + err));
  };

  getUserCondition = () => {
    const url = "/api/v1/users/get_user_condition";
    fetch(url, {
      headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
      .then((data) => {
      if (data.ok) {
        return data.json();
      }
      throw new Error("Network error.");
      }).then((data) => {
      this.setState({ userRole: data});
      })
      .catch();
  };

  getNumberOfLicensesLevel = (id) => {      
    const url = `/api/v1/levels/${id}/get_level_licenses`;
    fetch(url, {
      headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
      .then((data) => {
      if (data.ok) {
        return data.json();
      }
      throw new Error("Network error.");
      }).then((data) => {
        this.setState({ number_of_licenses: data.notice});
      })
      .catch();
  };

  render() {              
    let levelsComponent;
    if (this.state.userRole.notice != undefined) {
      switch (this.state.userRole.notice) {
        case "training_center_admin":
          levelsComponent =
          <>
            <div className="show-separator-title-box"></div>
            <Row className="training-center-show">
              <Col span={24} style={{padding: "25px 50px"}}>
                <LevelsUsers level = {this.state.levels} />                          
              </Col>
            </Row>
          </>
        break;
        case "admin":
          levelsComponent =
          <>
           <div className="show-separator-title-box"></div>
            <Row className="training-center-show">
              <Col span={24} style={{padding: "25px 50px"}}>
                <LevelsUsers level = {this.state.levels} />                          
              </Col>
            </Row>
          </>
        break;
        case "student":
          levelsComponent =
          <>
          
          </>
        break;
        case "instructor":
          levelsComponent =
          <>
          
          </>
        break;
      }
    }

    return(
      <>
        <Row gutter={16} className="top-row">
          <Col className="gutter-row" xl={8}>
           <h1> <Link className="icon-primary" to={"/courses"}><i className="bi bi-arrow-left-square-fill"></i></Link> Nivel: {this.state.levels.name}</h1>
          </Col>
          <Col className="gutter-row text-end" xl={12}>
           
          </Col>
          <Col className="gutter-row" xl={4}>
            
          </Col>

          <div className="show" style={{padding: "0px"}}>
            <Row>
              <Col span={8} style={{"maxHeight": "300px"}} >
                  <img className="logo w-100 h-100" src={defaultImg} style={{"objectFit": "cover"}} />
              </Col>
              <Col span={16} style={{padding: "25px 50px"}}>
                <h2>{this.state.levels.name}</h2>
                <p className="label_name">¿Es de instructor?</p>
                {this.state.levels.is_instructor
                 ? <>
                    <p className="description">Sí</p> 
                   </>
                 : <>
                    <p className="description">No</p>
                   </>
                }
                {this.state.number_of_licenses != ""
                 ? <>
                    <p className="label_name">Número de licencias de este nivel</p>
                    <p className="description">{this.state.number_of_licenses}</p>
                   </>
                 : <>
                   </>
                }              
              </Col>
            </Row>
            {levelsComponent}
          </div>
        </Row>
      </>
    );
  }
}

export default LevelsShow;