import { Row, Col} from "antd";
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import defaultImg from 'images/default_user_photo.jpg';
import Users from "components/users/Users";
import UsersCourses from "../users/UsersCourses";

class UsersShow extends Component {
    state = {
      users: [],
      userCourses: [],
    };

    componentDidMount() {
      this.getUserCourses(window.location.pathname.split("/").pop());  
    }

    UNSAFE_componentWillMount() {
        this.loadUser(window.location.pathname.split("/").pop());
    }

    getUserCourses = (id) => {
      const url = `/api/v1/users/${id}/get_user_courses`;
      fetch(url, {
        headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
        },})
        .then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");

        }).then((data) => {
          this.setState({
            userCourses: data.map(item => ({
              name: item.name
            }))   
          }); 
        })
        .catch();
    };

   loadUser = (id) => {
     const url = `/api/v1/users/${id}`;
     fetch(url, {
       method: "get",
       headers: {
         Accept: "application/json, text/plain, */*",
         Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
       },})
       .then((data) => {
         if (data.ok) {
           return data.json();
         }
         throw new Error("Network error.");
       })
       .then((data) => {
            this.setState({
                ...this.state, // Not required but just a heads up on using mutation
                users: data
            })
       })
       .catch((err) => message.error("Error: " + err));
    };

    Items = (courses) => {
      return courses.map(function (course) {
        return (
           <p>{course.name}</p>
        )
      })
    }

    get_training_center_name = (training_centers) => {
      return training_centers?.map(function (center) {
        return (
           <p>{center.name}</p>
        )
      })
    }

    render() {

        return(
          <>
          <Row gutter={16} className="top-row">
            <Col className="gutter-row" xl={8}>
             <h1> <Link className="icon-primary" to={"/users"}><i className="bi bi-arrow-left-square-fill"></i></Link> Alumno: {this.state.users.name}</h1>
            </Col>
            <Col className="gutter-row text-end" xl={12}>
             
            </Col>
            <Col className="gutter-row" xl={4}>
              
            </Col>

            <div className="show" style={{padding: "0px"}}>
                <Row>
                  <Col span={8} style={{"maxHeight": "300px"}} >
                      <img className="logo w-100 h-100" src={defaultImg} style={{"objectFit": "cover"}} />
                  </Col>
                  <Col span={16} style={{padding: "25px 50px"}}>
                        <h2>{this.state.users.name} {this.state.users.surname}</h2>
                        <p className="label_name"><i className="bi bi-envelope-fill"></i> Email del usuario</p>
                        <p>{this.state.users.email}</p>
                        <p className="label_name"><i className="bi bi-building"></i> Centro del usuario</p>
                        {this.get_training_center_name(this.state.users.training_centers)}
                  </Col>
                </Row>

                <div className="show-separator-title-box">Cursos</div>

                <Row className="training-center-show">
                  <Col span={24} style={{padding: "25px 50px"}}>
                    <UsersCourses user = {this.state.users} />
                  </Col>
              </Row>
            </div>
          </Row>
          </>
        );
    }
}

export default UsersShow;