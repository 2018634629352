import { Button, Form, Input, Modal, Select, notification } from "antd";
import React from "react";
import Users from "./Users";
import Courses from "components/courses/Courses";
import { EditOutlined } from '@ant-design/icons';

const { Option } = Select;

class EditUserModal extends React.Component {
  formRef = React.createRef();

  state = {
    visible: false,
    courses: [],
    levels: [],
    userLevels: [],
    userCourses: [],
    total_levels: [],
    levels_student: [],
    levels_instructor: [],
    value: "",

  };

  componentDidMount() {
    this.getUserCourses();
    this.getUserLevels();
    this.loadLevelsStudent();
    this.loadLevelsInstructor();  
    this.loadCourses();
    this.loadLevels();
  }

  handleChange = (event) => {
    if (event == "student") {
      this.setState({total_levels: this.state.levels_student});
    } else if (event == "instructor") {
      this.setState({total_levels: this.state.levels_instructor});
    } else {
      this.setState({total_levels: this.state.levels_student});
    }
  };

  getUserCourses = () => {
    const url = `/api/v1/users/${this.props.user.id}/get_user_courses`;
    fetch(url, {
      headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
      .then((data) => {
      if (data.ok) {
        return data.json();
      }
      throw new Error("Network error.");

      }).then((data) => {
        this.setState({
          userCourses: data.map(item => ({
            id: item.id
          }))   
        }); 
      })
      .catch();
  };


  getUserLevels = () => {
    const url = `/api/v1/users/${this.props.user.id}/get_user_levels`;
    fetch(url, {
      headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },})
      .then((data) => {
      if (data.ok) {
        return data.json();
      }
      throw new Error("Network error.");

      }).then((data) => {
        this.setState({
          userLevels: data.map(item => ({
            id: item.id,
            name: item.name
          }))   
        }); 
      })
      .catch();
  };

  loadLevelsStudent = () => {
    const url = "/api/v1/levels/get_levels_student";
    fetch(url, {
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },
    }).then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then((data) => {
        data.forEach((level_student) => {
          const newElStudent = {
            key: level_student.id,
            id: level_student.id,
            name: level_student.name,
            id_course_level_moodle: level_student.id_course_level_moodle,
            id_evaluation_level_moodle: level_student.id_evaluation_level_moodle,
            is_instructor: level_student.is_instructor
          };

          this.setState((prevState) => ({
            levels_student: [...prevState.levels_student, newElStudent],
          }));
        });
      })
      .catch((err) => message.error("Error: " + err));
  };

  loadLevelsInstructor = () => {
    const url = "/api/v1/levels/get_levels_instructor";
    fetch(url, {
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },
    }).then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then((data) => {
        data.forEach((levels_instructor) => {
          const newElInstructor = {
            key: levels_instructor.id,
            id: levels_instructor.id,
            name: levels_instructor.name,
            id_course_level_moodle: levels_instructor.id_course_level_moodle,
            id_evaluation_level_moodle: levels_instructor.id_evaluation_level_moodle,
            is_instructor: levels_instructor.is_instructor
          };

          this.setState((prevState) => ({
            levels_instructor: [...prevState.levels_instructor, newElInstructor],
          }));
        });
      })
      .catch((err) => message.error("Error: " + err));
  };

  loadCourses = () => {
    const url = "/api/v1/courses";
    fetch(url, {
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },
    }).then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then((data) => {
        data.forEach((course) => {
          const newEl = {
            key: course.id,
            id: course.id,
            name: course.name,
            id_course_moodle: course.id_course_moodle
          };

          this.setState((prevState) => ({
            courses: [...prevState.courses, newEl],
          }));
        });
      })
      .catch((err) => message.error("Error: " + err));
  };

  loadLevels = () => {
    const url = "/api/v1/levels";
    fetch(url, {
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
      },
    }).then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then((data) => {
        data.forEach((level) => {
          const newEl = {
            key: level.id,
            id: level.id,
            name: level.name,
            id_course_level_moodle: level.id_course_level_moodle,
            id_evaluation_level_moodle: level.id_evaluation_level_moodle
          };

          this.setState((prevState) => ({
            levels: [...prevState.levels, newEl],
          }));
        });
      })
      .catch((err) => message.error("Error: " + err));
  };

  onFinish = (values) => {
    // /api/v1/users/:user_id/update_user
    const url = `/api/v1/users/${this.props.user.id}/update_consumer`;
    fetch(url, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"

      },
      body: JSON.stringify(values),
    })
      .then((data) => {
        if (data.ok) {
          this.handleCancel();
          this.formRef.current.resetFields(); 
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then(() => {
        this.props.reloadUsers();
        this.props.reloadLicenses();
        notification.open({
                  message: 'Usuario modificado correctamente!',
                  placement: 'bottom',
                  icon: <EditOutlined style={{ color: '#394088' }} />,
                  duration: 4
                });
      })
      .catch((err) => console.error("Error: " + err));
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false
    });
  };

  selectItems = (courses) => {
    return courses.map(function (course) {
      return (
        <Option label={course.name} value={course.id}>{course.name}</Option>
      )
    })
  }

  userCourses = (user_courses) => {
    var results = [];
    for (let index in user_courses) {
        results.push(user_courses[index].id)
    }
    return results
  }

  userLevels = (user_levels) => {
    var results = [];
    for (let index in user_levels) {
      results.push(user_levels[index].name)
    }
    return results
  }

  render() {
    return (
      <>
        <Button className="action-btn" type="primary" onClick={()=>this.showModal()}>
          <i className="bi bi-pencil-square"></i>
        </Button>

        <Modal title="Editar Usuario ..." visible={this.state.visible} onCancel={this.handleCancel} footer={null}>
          <Form ref={this.formRef} layout="vertical" onFinish={this.onFinish} 
              initialValues={{ name: this.props.user.name, surname: this.props.user.surname, user_type: this.props.user.user_type }}>
            <Form.Item name="name" label="Nombre del alumno" rules={[{ required: true, message: "Por favor, introduce el nombre del alumno" }]}>
              <Input placeholder="Nombre del alumno"  />
            </Form.Item>

            <Form.Item name="surname" label="Apellidos del alumno" rules={[{ required: true, message: "Por favor, introduce los apellidos del alumno" }]}>
              <Input placeholder="Apellidos del alumno" />
            </Form.Item>
      
            {/*<Form.Item name="user_type" label="Rol del usuario" rules={[{ required: true, message: "Por favor, selecciona el rol del usuario" }]}>
              <Select 
                placeholder="Selecciona el rol del usuario"
                onChange={this.handleChange}
                options={[
                  {
                    value: 'student',
                    label: 'Alumno',
                  },
                  {
                    value: 'instructor',
                    label: 'Instructor',
                  },
                ]}
              />                            
            </Form.Item>

            <Form.Item name="level_ids" label="Cursos y niveles relacionados" rules={[{ required: true, message: "Por favor, selecciona el nivel del curso" }]}>
              <Select
                labelInValue 
                placeholder="Selecciona uno o varios niveles"
                mode="multiple"
                defaultValue={this.userLevels(this.state.userLevels)}
              >
                {this.selectItems(this.state.total_levels)}
              </Select>  
            </Form.Item>*/}

            <Form.Item>
              <Button type="primary" className="primary-btn" htmlType="submit">
                Actualizar
              </Button>
              <Button type="danger" onClick={()=>this.handleCancel()}>
                Cancelar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default EditUserModal;