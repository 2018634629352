import { Row, Col} from "antd";
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Courses from "./Courses";
import defaultImg from 'images/default_center_img';
import CoursesTrainingCenters from "../courses/CoursesTrainingCenters";
import CoursesLevels from "../courses/CoursesLevels";

class CoursesShow extends Component {
    state = {
      courses: [],
      userLogin: [],
      userRole: [],
      courseStatus: [],
      courseAvaluable: [],
      courseStudents: [],
      courseLicenses: [],
      courseStudentsRoleStudent: [],
      courseStudentsRoleInstructor: []
    };

    UNSAFE_componentWillMount() {
      this.loadCourse(window.location.pathname.split("/").pop());
      this.getUserCondition();
      this.getTotalCourseStudents(window.location.pathname.split("/").pop());
      this.getTotalCourseStudentsRoleStudent(window.location.pathname.split("/").pop());
      this.getTotalCourseStudentsRoleInstructor(window.location.pathname.split("/").pop());
      this.getTotalCourseLicenses(window.location.pathname.split("/").pop());
    }

    loadCourse = (id) => {
      const url = `/api/v1/courses/${id}`;
      fetch(url, {
        method: "get",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
        },})
        .then((data) => {
        if (data.ok) {
            return data.json();
          }
          throw new Error("Network error.");
        })
        .then((data) => {
          const singleCourse = {
            key: data.id,
            id: data.id,
            name: data.name,
            description: data.description,
            id_course_moodle: data.id_course_moodle,
            moodle_link: data.moodle_link,
            license_number: data.licenses.length
          };

          this.setState({
              ...this.state, // Not required but just a heads up on using mutation
              courses: singleCourse
          })
        })
       .catch((err) => message.error("Error: " + err));
    };

    getTotalCourseStudents = (id) => {
      const url = `/api/v1/courses/${id}/get_total_course_students`;
      fetch(url, {
        headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
        },})
        .then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
        }).then((data) => {
          this.setState({ courseStudents: data});
        })
        .catch();
    };


    getTotalCourseLicenses = (id) => {
      const url = `/api/v1/courses/${id}/get_total_course_licenses`;
      fetch(url, {
        headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
        },})
        .then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
        }).then((data) => {
          this.setState({ courseLicenses: data});
        })
        .catch();
    };


    getTotalCourseStudentsRoleStudent = (id) => {
      const url = `/api/v1/courses/${id}/get_total_course_role_student`;
      fetch(url, {
        headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
        },})
        .then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
        }).then((data) => {
          this.setState({ courseStudentsRoleStudent: data});
        })
        .catch();
    };

    getTotalCourseStudentsRoleInstructor = (id) => {
      const url = `/api/v1/courses/${id}/get_total_course_role_instructor`;
      fetch(url, {
        headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
        },})
        .then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
        }).then((data) => {
          this.setState({ courseStudentsRoleInstructor: data});
        })
        .catch();
    };

    getUserLogin = () => {
      const url = "api/v1/users/login_moodle";
      fetch(url, {
        headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
        },})
        .then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
        })
        .then((data) => {
          const students = {
            key: data.id,
            id: data.id,
            name: data.name,
            description: data.description,
            id_course_moodle: data.id_course_moodle,
            moodle_link: data.moodle_link,
            license_number: data.licenses.length
          };

          this.setState({
              ...this.state,
          })
        })
       .catch((err) => console.log(err));
      };

    getUserCondition = () => {
      const url = "/api/v1/users/get_user_condition";
      fetch(url, {
        headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
        },})
        .then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw new Error("Network error.");
        }).then((data) => {
        this.setState({ userRole: data});
        })
        .catch();
    };

    render() {
        let course_show_details;
        if (this.state.userRole.notice != undefined) {
          switch (this.state.userRole.notice) {
            case "training_center_admin":
              course_show_details =
              <>
                <p className="label_name">Nº de alumnos inscritos</p>
                <p className="description">{this.state.courseStudents.notice}</p> 
                <p className="label_name">Nº de licencias totales contratadas</p>
                <p className="description">{this.state.courseLicenses.notice}</p> 
                <p className="label_name">Nº de licencias restantes</p>
                <p className="description">{this.state.courseLicenses.notice - this.state.courseStudents.notice}</p> 
              </>
            break;
            case "admin":
              course_show_details =
              <>
                <p className="label_name">Nº de alumnos inscritos</p>
                <p className="description">{this.state.courses.license_number}</p>
              </>
            break;
            case "student":
              course_show_details = "";
            break;
            case "instructor":
              course_show_details = "";
            break;
          }
        };

        let levelsComponent;
        if (this.state.userRole.notice != undefined) {
          switch (this.state.userRole.notice) {
            case "training_center_admin":
              levelsComponent =
              <>
                <div className="show-separator-title-box"></div>
                <Row className="training-center-show">
                  <Col span={24} style={{padding: "25px 50px"}}>
                    <CoursesLevels course = {this.state.courses} />                          
                  </Col>
                </Row>
              </>
            break;
            case "admin":
              levelsComponent =
              <>
               <div className="show-separator-title-box"></div>
                <Row className="training-center-show">
                  <Col span={24} style={{padding: "25px 50px"}}>
                    <CoursesLevels course = {this.state.courses} />                          
                  </Col>
                </Row>
              </>
            break;
            case "student":
              levelsComponent =
              <>
              
              </>
            break;
          }
        }

        return(
          <>
          <Row gutter={16} className="top-row">
            <Col className="gutter-row" xl={8}>
             <h1> <Link className="icon-primary" to={"/courses"}><i className="bi bi-arrow-left-square-fill"></i></Link> Curso: {this.state.courses.name}</h1>
            </Col>
            <Col className="gutter-row text-end" xl={12}>
             
            </Col>
            <Col className="gutter-row" xl={4}>
              
            </Col>

            <div className="show" style={{padding: "0px"}}>
                <Row>
                  <Col span={8} style={{"maxHeight": "300px"}} >
                      <img className="logo w-100 h-100" src={defaultImg} style={{"objectFit": "cover"}} />
                  </Col>
                  <Col span={16} style={{padding: "25px 50px"}}>
                    <h2>{this.state.courses.name}</h2>
                    {course_show_details}
                  </Col>
                </Row>
                {levelsComponent}
                
            </div>
          </Row>
          </>
        );
    }
}

export default CoursesShow;