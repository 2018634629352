import { Button, Form, Input, Modal, Select, Row, Col, notification} from "antd";
import { CheckCircleOutlined } from '@ant-design/icons';
import React, { Component, useState } from 'react';



class EditCourseSpecialistModal extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
  }

  state = {
    visible: false,
    specialist_image: "",
    imageModify: false
  };


  onImageUpload = (specialist_id) => {
    //UpdateImage
    const urlImg = `api/v1/specialists/${specialist_id}/attach_image_to_specialist`;
    let formData = new FormData()
    formData.append('image', this.state.specialist_image)
    fetch(urlImg, {
        method: 'PUT',
        body: formData
    })
    .then(resp => resp.json())
    .then(data => {
        if (data.errors) {
          alert(data.errors)
        }
        else {
          console.log(data)
        }
    })
  }

  onFinish = (values) => {

    const url = `api/v1/specialists/${this.props.specialist.id}/update_specialist`;
    fetch(url, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"

      },
      body: JSON.stringify(values),
    })
      .then((data) => {
        if (data.ok) {
          this.handleCancel();
          this.formRef.current.resetFields();           
          return data.json();
        }
        throw new Error("Network error.");
      })
      .then((data) => {
        if(this.state.modifyImage) {
            this.onImageUpload(data.id);
        }
        this.props.reloadSpecialists();
        notification.open({
                  message: 'Especialista añadido correctamente!',
                  placement: 'bottom',
                  icon: <CheckCircleOutlined style={{ color: '#4BDAB1' }} />,
                  duration: 4
                });
      })
      .catch((err) => console.error("Error: " + err));
      

      
      
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  onImageChange = (e) => {
    var file = e.target.files[0];
    this.setState((prevState) => ({
        specialist_image: file,
    }));
  }

  render() {

    return (
      <>
        <button className="action-btn" type="primary" onClick={this.showModal}>
            <i className="bi bi-pencil-square"></i>
        </button>

        <Modal title="Añade un nuevo especialista ..." visible={this.state.visible} onCancel={this.handleCancel} footer={null}>
          <Form ref={this.formRef} layout="vertical" onFinish={this.onFinish} initialValues={{name: this.props.specialist.name, position: this.props.specialist.position}}>

          <Row gutter={16}>
            <Col className="gutter-row" span={24}>
              {/* Nombre */}
              <Form.Item name="name" label="Especialista" rules={[{ required: true, message: "Por favor, introduce el nombre del especialista que quieres añadir" }]}>
                <Input placeholder="Dr. Nombre Apellidos" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              {/* Cargo */}
              <Form.Item name="position" label="Cargo" rules={[{ required: false, message: "Por favor, introduce el cargo del especialista que quieres añadir" }]}>
                <Input placeholder="Cargo" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              {/* Imagen */}
              <Form.Item name="Img" label="Cambiar la foto del especialista" rules={[{ required: false, message: "Por favor, introduce la imagen del especialista" }]}>
                <input type="file" onChange={this.onImageChange} accept="image/png, image/jpeg"/>
              </Form.Item>
            </Col>
          </Row>       
                     
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Insertar
              </Button>
              <Button type="danger" onClick={()=>this.handleCancel()}>
                Cancelar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default EditCourseSpecialistModal;