import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import App from "./App";
import { Row, Col, Layout, notification, Button } from "antd";
import TrainingCenters from "./training_centers/TrainingCenters";
import Header from "./header/Header";
import VerticalNav from "./VerticalNav";
import TrainingCentersShow from "./training_centers/TrainingCentersShow";
import Courses from "./courses/Courses";
import Users from "./users/Users";
import UserShow from "./users/UsersShow";
import CoursesShow from "./courses/CoursesShow";
import LevelsShow from "./levels/LevelsShow";
import PageNotFound_404 from "./PageNotFound_404";

const { Footer, Sider, Content } = Layout;

class RoutesSecurity extends React.Component {

  state = {
		userRole: [],
	  };

	componentDidMount() {
		this.getUserCondition();
	}

  getUserCondition = () => {
		const url = "/api/v1/users/get_user_condition";
		fetch(url, {
		  headers: {
			Accept: "application/json, text/plain, */*",
			Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjU1MjEzNTk1LCJleHAiOjE2NTY1MDk1OTUsImp0aSI6ImNkOTY4ODBiLWJmMGEtNDQ3Yy1iZjU0LWVhMmJjYjg0NWQ1NyJ9.Ze71ZywB-Y6vxVga9_RmwY6CTURIq3tSFHgGKCZLkBQ"
		  },})
		  .then((data) => {
			if (data.ok) {
			  return data.json();
			}
			throw new Error("Network error.");
		  }).then((data) => {
			this.setState({ userRole: data});
		  })
		  .catch();
	};


  render(){

    let layout_routes;
    if (this.state.userRole.notice != undefined) {
      switch (this.state.userRole.notice) {
        case "training_center_admin":
          layout_routes=
          <>
          <Router>
            <Layout className="layout min-vh-100">
              <Header/>
              <Layout>
                <Sider
                  className="hcit-sidebar"
                  breakpoint={"lg"}
                  width="300"
                  >
                  <VerticalNav/>
                </Sider>
                <Content>
                  <div className="hcit-content">
                  <Routes>
                    <Route path="/courses" element={<Courses />} />
                    {/* <Route path="/course/:id" element={<CoursesShow />} />*/}
                    <Route path="/users" element={<Users />} />
                    <Route path="/user/:id" element={<UserShow />} />
                    <Route path="/training_center/:id" element={<TrainingCentersShow />} />
                    <Route path="/training_center" element={<TrainingCenters />} />
                    <Route path="/courses" element={<Courses />} />
                    <Route path="/course/:id" element={<CoursesShow />} />
                    <Route path="/level/:id" element={<LevelsShow />} />
                    <Route path="/" element={<Courses />} />
                    <Route path='*' exact={true} element={<PageNotFound_404 />} />
                  </Routes>
                  

                  </div>
                </Content>
              </Layout>
            </Layout>
          </Router>
          
          </>
          break;
        case "admin":
          layout_routes=
            <>
              <Router>
                <Layout className="layout min-vh-100">
                  <Header/>
                  <Layout>
                    <Sider
                      className="hcit-sidebar"
                      breakpoint={"lg"}
                      width="300"
                      >
                      <VerticalNav/>
                    </Sider>
                    <Content>
                      <div className="hcit-content">
                      <Routes>
                        <Route path="/training_centers" element={<TrainingCenters />} />
                        <Route path="/training_center/:id" element={<TrainingCentersShow />} />
                        <Route path="/courses" element={<Courses />} />
                        <Route path="/course/:id" element={<CoursesShow />} />
                         <Route path="/level/:id" element={<LevelsShow />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/user/:id" element={<UserShow />} />
                        <Route path='*' exact={true} element={<PageNotFound_404 />} />
                      </Routes>
                      

                      </div>
                    </Content>
                  </Layout>
                </Layout>
              </Router>
            </>
          break;
        case "student":
          layout_routes=
            <>
              <Router>
                <Layout className="layout min-vh-100">
                  <Header/>
                  <Layout>
                    <Sider
                      className="hcit-sidebar"
                      breakpoint={"lg"}
                      width="300"
                      >
                      <VerticalNav/>
                    </Sider>
                    <Content>
                      <div className="hcit-content">
                      <Routes>
                        <Route path="/" element={<Courses />} />
                        <Route path="/courses" element={<Courses />} />
                        <Route path="/course/:id" element={<CoursesShow />} />
                        <Route path='*' exact={true} element={<PageNotFound_404 />} />
                      </Routes>
                      

                      </div>
                    </Content>
                  </Layout>
                </Layout>
              </Router>
            </>
          break;
        case "instructor":
          layout_routes=
            <>
              <Router>
                <Layout className="layout min-vh-100">
                  <Header/>
                  <Layout>
                    <Sider
                      className="hcit-sidebar"
                      breakpoint={"lg"}
                      width="300"
                      >
                      <VerticalNav/>
                    </Sider>
                    <Content>
                      <div className="hcit-content">
                      <Routes>
                        <Route path="/" element={<Courses />} />
                        <Route path="/courses" element={<Courses />} />
                        <Route path="/course/:id" element={<CoursesShow />} />
                        <Route path='*' exact={true} element={<PageNotFound_404 />} />
                      </Routes>
                      

                      </div>
                    </Content>
                  </Layout>
                </Layout>
              </Router>
            </>
          break;			
      }
    }
      
    return(
      <>
        {layout_routes}
      </>
    )
      
  }
}

export default RoutesSecurity;